import { setSelectedFilterValue, setDateFilter, setNumberFilter, toApiFilters } from './filtersConverter';
import { FilterType, Filter } from '@commonServices/models/FilterType';
import { WorkQueueType } from '@commonServices/models/WorkQueueType';
import { claimNumberStatus } from '@commonServices/models/ClaimStatusActionEnum';
import DraftClaimModel from '@commonServices/models/DraftClaimModel';
import ClaimPaymentToApproveModel from '@commonServices/models/ClaimPaymentToApproveModel';
import ClaimRejectionToApproveModel from '@commonServices/models/ClaimRejectionToApproveModel';
import ClaimToFraudCheckModel from '@commonServices/models/ClaimToFraudCheckModel';
import ClaimToChaseModel from '@commonServices/models/ClaimToChaseModel';
import WorkQueueModel from '@commonServices/models/WorkQueueModel';
import TasksItemsModel from '@commonServices/models/TasksItemsModel';
import groupBy from 'lodash.groupby';
import { toUser } from './converter';
import { fromStringToDate } from '@commonServices/utils/dateUtils';
import { PolicyStatusDisplay } from '@commonServices/models/PolicyStatus';

const ViewFilterConvertersMap = {
	[WorkQueueType.DraftClaims]: toDraftViewFilter,
	[WorkQueueType.PaymentApproval]: toSettledViewFilter,
	[WorkQueueType.FraudCheck]: toFraudCheckViewFilter,
	[WorkQueueType.RejectionApproval]: toRejectionPendingViewFilter,
	[WorkQueueType.Chasing]: toMissingInfoViewFilter,
	[WorkQueueType.ReassessmentPending]: toReassessmentPendingViewFilter,
};

const ClaimStatusTaskName = Object.freeze({
	[claimNumberStatus.Settled]: 'Payment Approval',
	[claimNumberStatus.FraudCheck]: 'Fraud Check',
	[claimNumberStatus.RejectionPending]: 'Rejection Check',
	[claimNumberStatus.MissingInfo]: 'Missing Information',
	[claimNumberStatus.ReassessmentPending]: 'Reassessment',
	[claimNumberStatus.Draft]: 'Claim Processing',
	[claimNumberStatus.FraudCheckOk]: 'Claim Processing',
});

// filters to view
export function toWorkQueueViewFilter (pot, apiFiltersList) {
	const apiFilters = groupBy(apiFiltersList, 'type');
	return ViewFilterConvertersMap[pot](apiFilters);
}

export function toDraftViewFilter (apiFilters) {
	const filter = getCommonFilter(apiFilters);
	filter.selectedConditionType = setSelectedFilterValue(apiFilters[FilterType.ConditionType], Filter.ConditionType, FilterType.ConditionType, true);
	filter.selectedCountry = setSelectedFilterValue(apiFilters[FilterType.Country], Filter.Country, FilterType.Country, true);
	filter.numbers.dayCountToTreatmentStart = setNumberFilter(apiFilters[FilterType.DaysCountToTreatmentStartFrom], apiFilters[FilterType.DaysCountToTreatmentStartTo], apiFilters[FilterType.DaysCountToTreatmentStartEqual], Filter.DayCountToTreatmentStart);
	filter.selectedClaimCreatedByUser = setSelectedFilterValue(apiFilters[FilterType.ClaimCreatedBy], Filter.ClaimCreatedBy, FilterType.ClaimCreatedBy, true);
	filter.isFastTrackReviewed = setSelectedFilterValue(apiFilters[FilterType.IsFastTrackReviewed], Filter.FastTrackReviewed, FilterType.IsFastTrackReviewed);
	filter.missingInfoCompleted = setSelectedFilterValue(apiFilters[FilterType.MissingInfoCompleted], Filter.MissingInfoCompleted, FilterType.MissingInfoCompleted);
	filter.selectedAssignedTo = setSelectedFilterValue(apiFilters[FilterType.ClaimAssignedToId], Filter.ClaimAssignedTo, FilterType.ClaimAssignedToId, true);
	filter.numbers.daysOpen = setNumberFilter(apiFilters[FilterType.DaysOpenFrom], apiFilters[FilterType.DaysOpenTo], apiFilters[FilterType.DaysOpenEqual], Filter.DaysOpen);
	filter.selectedPaymentRecipient = setSelectedFilterValue(apiFilters[FilterType.PaymentRecipient], Filter.PaymentRecipient, FilterType.PaymentRecipient, true);

	return filter;
}

export function toSettledViewFilter (apiFilters) {
	const filter = getCommonFilter(apiFilters);
	filter.numbers.paymentAmount = setNumberFilter(apiFilters[FilterType.PaymentAmountFrom], apiFilters[FilterType.PaymentAmountTo], apiFilters[FilterType.PaymentAmountEqual], Filter.PaymentAmount);
	filter.numbers.exGratiaAmount = setNumberFilter(apiFilters[FilterType.ExGratiaPaymentFrom], apiFilters[FilterType.ExGratiaPaymentTo], apiFilters[FilterType.ExGratiaPaymentEqual], Filter.ExGratiaAmount);
	filter.selectedRequestedByUser = setSelectedFilterValue(apiFilters[FilterType.RequestedBy], Filter.RequestedBy, FilterType.RequestedBy, true);
	filter.selectedSettledReason = setSelectedFilterValue(apiFilters[FilterType.SettledReason], Filter.SettledReason, FilterType.SettledReason, true);
	return filter;
}

export function toFraudCheckViewFilter (apiFilters) {
	const filter = getCommonFilter(apiFilters);
	filter.selectedFraudFlag = setSelectedFilterValue(apiFilters[FilterType.FraudFlag], Filter.FraudFlags, FilterType.FraudFlag, true);
	filter.dates.fraudCheckRequestedDate = setDateFilter(apiFilters[FilterType.FraudCheckRequestedDateFrom], apiFilters[FilterType.FraudCheckRequestedDateTo], Filter.FraudCheckRequestedDate);
	return filter;
}

export function toRejectionPendingViewFilter (apiFilters) {
	const filter = getCommonFilter(apiFilters);
	filter.selectedReason = setSelectedFilterValue(apiFilters[FilterType.RejectionReason], Filter.RejectionReasons, FilterType.RejectionReason, true);
	filter.selectedRejectionStatus = setSelectedFilterValue(apiFilters[FilterType.RejectionStatus], Filter.Rejection, FilterType.RejectionStatus, true);
	return filter;
}

export function toMissingInfoViewFilter (apiFilters) {
	const filter = getCommonFilter(apiFilters);
	filter.selectedMissingItem = setSelectedFilterValue(apiFilters[FilterType.MissingItem], Filter.MissingItem, FilterType.MissingItem, true);
	filter.numbers.chaseCount = setNumberFilter(apiFilters[FilterType.ChaseCountFrom], apiFilters[FilterType.ChaseCountTo], apiFilters[FilterType.ChaseCountEqual], Filter.ChaseCount);
	filter.dates.missingInfoRequestedDate = setDateFilter(apiFilters[FilterType.MissingInfoDateFrom], apiFilters[FilterType.MissingInfoDateTo], Filter.MissingInfoRequestedDate);
	filter.selectedRequestedByUser = setSelectedFilterValue(apiFilters[FilterType.RequestedBy], Filter.RequestedBy, FilterType.RequestedBy, true);
	return filter;
}

export function toReassessmentPendingViewFilter (apiFilters) {
	const filter = getCommonFilter(apiFilters);
	filter.dates.reassessmentRequestedDate = setDateFilter(apiFilters[FilterType.ReassessmentRequestedDateFrom], apiFilters[FilterType.ReassessmentRequestedDateTo], Filter.ReassessmentRequestedDate);
	return filter;
}

function getCommonFilter (apiFilters) {
	const filter = {
		dates: {
		},
		numbers: {
		},
	};

	filter.selectedBrand = setSelectedFilterValue(apiFilters[FilterType.Brand], Filter.Brand, FilterType.Brand, true);
	filter.selectedProduct = setSelectedFilterValue(apiFilters[FilterType.Product], Filter.Product, FilterType.Product, true);
	filter.selectedBreed = setSelectedFilterValue(apiFilters[FilterType.Breed], Filter.Breed, FilterType.Breed, true);
	filter.selectedPetType = setSelectedFilterValue(apiFilters[FilterType.PetType], Filter.Species, FilterType.PetType);
	filter.selectedCounty = setSelectedFilterValue(apiFilters[FilterType.County], Filter.County, FilterType.County, true);
	filter.selectedVet = setSelectedFilterValue(apiFilters[FilterType.Vet], Filter.Vet, FilterType.Vet, true);
	filter.selectedClaimType = setSelectedFilterValue(apiFilters[FilterType.ClaimType], Filter.ClaimType, FilterType.ClaimType);
	filter.selectedAilment = setSelectedFilterValue(apiFilters[FilterType.Ailment], Filter.Ailment, FilterType.Ailment, true);
	filter.selectedPolicySection = setSelectedFilterValue(apiFilters[FilterType.PolicySection], Filter.PolicySections, FilterType.PolicySection, true);
	filter.selectedCareFlag = setSelectedFilterValue(apiFilters[FilterType.CareFlag], Filter.CareFlag, FilterType.CareFlag, true);
	filter.isDead = setSelectedFilterValue(apiFilters[FilterType.IsDead], Filter.DeceasedPet, FilterType.IsDead);
	filter.selectedReassessmentReason = setSelectedFilterValue(apiFilters[FilterType.ReassessmentReason], Filter.ReassessmentReason, FilterType.ReassessmentReason, true);
	filter.numbers.claimAmount = setNumberFilter(apiFilters[FilterType.ClaimAmountFrom], apiFilters[FilterType.ClaimAmountTo], apiFilters[FilterType.ClaimAmountEqual], Filter.ClaimAmount);
	filter.dates.claimCreatedDate = setDateFilter(apiFilters[FilterType.ClaimCreatedDateFrom], apiFilters[FilterType.ClaimCreatedDateTo], Filter.ClaimCreatedDate);
	filter.selectedClaimFormType = setSelectedFilterValue(apiFilters[FilterType.ClaimFormType], Filter.FormType, FilterType.ClaimFormType, true);
	filter.claim = setSelectedFilterValue(apiFilters[FilterType.Claim], Filter.Claim, FilterType.Claim, false, null, false);
	filter.isEcalated = setSelectedFilterValue(apiFilters[FilterType.IsEscalated], Filter.Escalated, FilterType.IsEscalated);
	filter.isReassessment = setSelectedFilterValue(apiFilters[FilterType.IsReassessment], Filter.IsReassessment, FilterType.IsReassessment);
	filter.selectedEscalationType = setSelectedFilterValue(apiFilters[FilterType.EscalationType], Filter.EscalationType, FilterType.EscalationType, true);
	filter.selectedLanguagePreference = setSelectedFilterValue(apiFilters[FilterType.LanguagePreference], Filter.LanguagePreference, FilterType.LanguagePreference, true);

	return filter;
}

// to api
export function toWorkQueueApiFilters (filterData) {
	return toApiFilters(filterData);
}

function toDraftClaimModel ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((claim) =>
			new DraftClaimModel(
				claim.brandId,
				claim.brandName,
				claim.brandLocale,
				claim.claimId,
				claim.claimStatus,
				claim.customerFirstName,
				claim.customerLastName,
				claim.customerId,
				claim.petId,
				claim.petName,
				claim.petType,
				claim.petGender,
				claim.petDateOfDeath,
				claim.vets,
				claim.claimAmount,
				claim.ailments,
				toUser(claim.requestedBy),
				claim.dateRequested,
				claim.policyStatus,
				claim.policies,
				PolicyStatusDisplay[claim.policyStatus],
				claim.productNames,
				claim.claimType,
				claim.daysOpen,
				toUser(claim.createdBy),
				claim.dateCreated,
				toUser(claim.assignedTo),
				claim.reassessmentDateTime,
				claim.claimFormType,
				claim.latestDashboardNote,
				claim.latestInteraction,
				claim.missingInfoCompleted,
			),
		),
	};
}

function toClaimPaymentToApproveModel ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((claim) =>
			new ClaimPaymentToApproveModel(
				claim.brandId,
				claim.brandName,
				claim.brandLocale,
				claim.claimId,
				claim.claimStatus,
				claim.customerFirstName,
				claim.customerLastName,
				claim.customerId,
				claim.petId,
				claim.petName,
				claim.petType,
				claim.petGender,
				claim.petDateOfDeath,
				claim.vets,
				claim.claimAmount,
				claim.suggestedClaimAmount,
				claim.adjusterComment,
				claim.ailments,
				claim.payees,
				claim.daysOpen,
				toUser(claim.requestedBy),
				claim.dateRequested,
				claim.policyStatus,
				claim.policies,
				PolicyStatusDisplay[claim.policyStatus],
				claim.canProceedPayment,
				toUser(claim.assignedTo),
				claim.exGratiaPayees,
				claim.settledAmount,
				claim.settledReason,
				claim.latestDashboardNote,
				claim.latestInteraction,
			),
		),
	};
}

function toClaimRejectionToApproveModel ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((claim) =>
			new ClaimRejectionToApproveModel(
				claim.brandId,
				claim.brandName,
				claim.brandLocale,
				claim.claimId,
				claim.claimStatus,
				claim.customerFirstName,
				claim.customerLastName,
				claim.customerId,
				claim.petId,
				claim.petName,
				claim.petType,
				claim.petGender,
				claim.petDateOfDeath,
				claim.vets,
				claim.claimAmount,
				claim.suggestedClaimAmount,
				claim.ailments,
				claim.payees,
				claim.daysOpen,
				claim.rejectionReason,
				claim.comments,
				toUser(claim.requestedBy),
				claim.dateRequested,
				claim.policyStatus,
				claim.policies,
				PolicyStatusDisplay[claim.policyStatus],
				toUser(claim.assignedTo),
				claim.claimFormType,
				claim.wasReassessed,
				claim.rejectionStatus,
				claim.latestDashboardNote,
				claim.latestInteraction,
			),
		),
	};
}

function toClaimsToFraudCheckModel ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((claim) =>
			new ClaimToFraudCheckModel(
				claim.brandId,
				claim.brandName,
				claim.brandLocale,
				claim.claimId,
				claim.claimStatus,
				claim.customerFirstName,
				claim.customerLastName,
				claim.customerId,
				claim.petId,
				claim.petName,
				claim.petType,
				claim.petGender,
				claim.petDateOfDeath,
				claim.vets,
				claim.claimAmount,
				claim.suggestedClaimAmount,
				claim.ailments,
				claim.payees,
				claim.daysOpen,
				claim.fraudCheckReasons,
				claim.fraudCheckComment,
				toUser(claim.requestedBy),
				claim.dateRequested,
				claim.policyStatus,
				claim.policies,
				PolicyStatusDisplay[claim.policyStatus],
				toUser(claim.assignedTo),
				claim.claimFormType,
				claim.wasReassessed,
				claim.latestDashboardNote,
				claim.latestInteraction,
			),
		),
	};
}

function toClaimsToChaseModel ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((claim) =>
			new ClaimToChaseModel(
				claim.brandId,
				claim.brandName,
				claim.brandLocale,
				claim.claimId,
				claim.claimStatus,
				claim.customerFirstName,
				claim.customerLastName,
				claim.customerId,
				claim.petId,
				claim.petName,
				claim.petType,
				claim.petGender,
				claim.petDateOfDeath,
				claim.vets,
				claim.claimAmount,
				claim.ailments,
				claim.missingInformation,
				claim.chased,
				claim.chaseCount,
				toUser(claim.requestedBy),
				claim.dateRequested,
				claim.chaseLimit,
				claim.policyStatus,
				claim.policies,
				PolicyStatusDisplay[claim.policyStatus],
				toUser(claim.assignedTo),
				claim.claimFormType,
				claim.latestDashboardNote,
				claim.latestInteraction,
			),
		),
	};
}

function toClaimsToReassessModel ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((claim) =>
			new WorkQueueModel(
				claim.brandId,
				claim.brandName,
				claim.brandLocale,
				claim.claimId,
				claim.claimStatus,
				claim.customerFirstName,
				claim.customerLastName,
				claim.customerId,
				claim.petId,
				claim.petName,
				claim.petType,
				claim.petGender,
				claim.petDateOfDeath,
				claim.vets,
				claim.claimAmount,
				claim.ailments,
				toUser(claim.requestedBy),
				claim.dateRequested,
				claim.policyStatus,
				claim.policies,
				PolicyStatusDisplay[claim.policyStatus],
				toUser(claim.assignedTo),
				WorkQueueType.ReassessmentPending,
				claim.latestDashboardNote,
				claim.latestInteraction,
			),
		),
	};
}

export function toClaimStatistics (claimStatistics) {
	return claimStatistics.map((stat) =>
		({
			pot: stat.workQueueType,
			count: stat.count,
		}),
	);
}

const WorkQueueTypeModelMap = {
	[WorkQueueType.DraftClaims]: toDraftClaimModel,
	[WorkQueueType.PaymentApproval]: toClaimPaymentToApproveModel,
	[WorkQueueType.FraudCheck]: toClaimsToFraudCheckModel,
	[WorkQueueType.RejectionApproval]: toClaimRejectionToApproveModel,
	[WorkQueueType.Chasing]: toClaimsToChaseModel,
	[WorkQueueType.ReassessmentPending]: toClaimsToReassessModel,
};

export function getWorkQueueModelConverter (pot) {
	return WorkQueueTypeModelMap[pot];
}

export function getTaskNameByStatus (claimStatusId) {
	return ClaimStatusTaskName[claimStatusId] || '';
}

export function toTasksTableModel (items) {
	return items.map((task) =>
		new TasksItemsModel(
			task.claimId,
			task.claimStatus,
			task.claimSource,
			task.customerId,
			task.customerFirstName,
			task.customerLastName,
			task.petId,
			task.petName,
			task.petType,
			task.petGender,
			task.petDateOfDeath,
			task.brandId,
			task.brandName,
			task.brandLocale,
			task.claimAmount,
			task.ailments,
			fromStringToDate(task.createdDate),
			fromStringToDate(task.openedDate),
			task.policyStatus,
			task.policies,
			PolicyStatusDisplay[task.policyStatus],
			task.workQueueType,
			task.workQueueName,
			task.prioritizedWorkQueue,
			task.daysOpen,
			task.missingInformation,
			task.fraudCheckReasons,
			task.rejectionReason,
			task.payees,
			task.payeeTypes,
			task.chaseCount,
			task.chased,
			task.chaseLimit,
			task.canProceedPayment,
			task.wasReassessed,
			task.autoAssigned,
			task.rejectionStatus,
			task.isFastTrackReviewed,
			task.missingInfoCompleted,
			task.latestDashboardNote,
			task.latestInteraction,
			task.formType,
			task.assignmentAcknowedgedDate,
		),
	);
}
