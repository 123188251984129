import httpClient from './httpClient';
import {
	toClaimItemSummaryView,
	toClaimBasicView,
	toClaimItemInput,
	toClaimCondition,
	toClaimItemsWithStatus,
	toClaimMissingItems,
	toClaimPaymentRecipients,
	toPaymentTransactions,
	toPaymentDetails,
	toPolicySectionGraphs,
	fromScannedtoClaimItemView,
	toParentPetClaim,
	toTreatmentPeriod,
	toPolicyDates,
	toPetClaimsData,
} from '@commonServices/utils/converter';
import { getQueryString } from '@commonServices/utils/general';
import LocalDate from '@commonServices/models/LocalDate';

export default {
	getClaim (claimId) {
		return httpClient(`/api/claims/${claimId}`)
			.get()
			.then(toClaimBasicView);
	},
	createClaim (petId, parentClaimId = null) {
		const createClaimRequest = {
			parentClaimId,
		};
		return httpClient(`/api/claims/pets/${petId}/claim`)
			.post(createClaimRequest);
	},
	createClaimCondition (claimId, ailmentId, bodyPartId, conditionTypeId) {
		const createClaimConditionRequest = {
			ailmentId,
			bodyPartId,
			conditionTypeId,
		};
		return httpClient(`/api/claims/${claimId}/condition`)
			.post(createClaimConditionRequest)
			.then(toClaimCondition);
	},
	createLinkedClaim (parentClaimId, fileId, claimData) {
		const claim = {
			...claimData,
			dateOfLoss: new LocalDate(claimData.dateOfLoss),
			dateOfDeath: new LocalDate(claimData.dateOfDeath),
			treatmentStart: new LocalDate(claimData.treatmentStart),
			treatmentEnd: new LocalDate(claimData.treatmentEnd),
		};
		return httpClient(`/api/claims/create-linked?parentClaimId=${parentClaimId}&initialFileMetadataId=${fileId}`)
			.post(claim);
	},
	unlinkClaim (claimId) {
		return httpClient(`/api/claims/${claimId}/unlink`)
			.put();
	},
	ungroupClaimCondition (claimConditionId) {
		return httpClient(`/api/claims/condition/${claimConditionId}/ungroup`)
			.put();
	},
	getLinkedClaimData (claimId) {
		return httpClient(`/api/claims/${claimId}/linked-data`)
			.get();
	},
	proceedClaim (claimId, claimData) {
		const claim = {
			...claimData,
			dateOfLoss: new LocalDate(claimData.dateOfLoss),
			dateOfDeath: new LocalDate(claimData.dateOfDeath),
			treatmentFromDate: new LocalDate(claimData.treatmentFromDate),
			treatmentToDate: new LocalDate(claimData.treatmentToDate),
		};
		return httpClient(`/api/claims/${claimId}`)
			.put(claim);
	},
	cancelClaim (claimId) {
		return httpClient(`/api/claims/${claimId}`)
			.delete();
	},
	checkWaitPeriod (data) {
		const waitPeriod = {
			...data,
			dateOfLoss: new LocalDate(data.dateOfLoss),
		};
		return httpClient('/api/claims/waitingPeriod')
			.post(waitPeriod);
	},
	checkProductCoverage (data) {
		const request = {
			...data,
			dateOfLoss: new LocalDate(data.dateOfLoss),
		};
		return httpClient('/api/claims/validate-product-coverage')
			.post(request);
	},
	validateDuplicateClaim (claimId, dateOfLoss, amount) {
		const payload = {
			claimId,
			dateOfLoss: new LocalDate(dateOfLoss),
			amount,
		};
		return httpClient('/api/claims/validate-duplicate-claim').post(payload);
	},
	validateDuplicateInvoice (claimId) {
		return httpClient(`/api/claims/${claimId}/validate-duplicate-invoice`).post();
	},
	getAilments (searchTerms, conditionTypeId = null) {
		let queryParam = `?searchTerms=${searchTerms}`;
		if (conditionTypeId) {
			queryParam += `&conditionTypeId=${conditionTypeId}`;
		}

		return httpClient(`/api/claims/ailment${queryParam}`, { omitSpinner: true })
			.get();
	},
	getVets (searchTerms) {
		return httpClient(`/api/claims/vets?searchTerms=${searchTerms}`, { omitSpinner: true })
			.get();
	},
	addVet (data) {
		return httpClient('/api/claims/vets')
			.post(data);
	},
	getClaimMissingItems (claimId) {
		return httpClient(`/api/claims/missing-items/${claimId}`)
			.get();
	},
	getMissingItems () {
		return httpClient('/api/claims/missing-items')
			.get();
	},
	getMissingItemContacts (claimId) {
		return httpClient(`/api/claims/missing-items/contacts/${claimId}`)
			.get();
	},
	saveClaimMissingItems (claimId, data, reassessmentOutcomeId = null, rejectionData = {
		isManuallyRejected: false,
		rejectionReasonId: null,
		comment: null,
	}, fraudCheck = false, fraudCheckReasons = [], fraudCheckComment = null) {
		return httpClient(`/api/claims/missing-items/${claimId}`)
			.post(toClaimMissingItems(data, rejectionData, reassessmentOutcomeId, fraudCheck, fraudCheckReasons, fraudCheckComment));
	},
	getPolicySections (claimId) {
		return httpClient(`/api/claims/policy/sections/${claimId}`)
			.get();
	},
	getPolicySectionGraphs (policyTermId, claimId = null, claimConditionId = null) {
		let queryParam = '?';
		if (claimId) {
			queryParam += `claimId=${claimId}`;
			if (claimConditionId) {
				queryParam += `&claimConditionId=${claimConditionId}`;
			}
		}

		return httpClient(`/api/claims/policy/term/${policyTermId}/graphs${queryParam}`, { omitSpinner: true })
			.get()
			.then(toPolicySectionGraphs);
	},
	getTreatmentPeriods (claimId, claimConditionId, policySectionId) {
		return httpClient(`/api/claims/${claimId}/treatment-periods?claimConditionId=${claimConditionId}&policySectionId=${policySectionId}`)
			.get()
			.then(data => data.map(toTreatmentPeriod));
	},

	calculateClaimItem (claimId, data) {
		const payload = {
			...data,
			claimItems: data.claimItems.map(x => toClaimItemInput(x)),
		};
		return httpClient(`/api/claims/${claimId}/deduct-excesses`)
			.post(payload)
			.then(toClaimItemsWithStatus);
	},
	getPolicyDates (claimId) {
		return httpClient(`/api/claims/${claimId}/details`)
			.get()
			.then(toPolicyDates);
	},
	checkBankAccountSortCode (data) {
		return httpClient('/api/claims/bank-accounts')
			.post(data);
	},
	getPaymentRecipients (claimId) {
		return httpClient(`/api/claims/${claimId}/payments/recipients`)
			.get()
			.then(toClaimPaymentRecipients);
	},
	getPaymentTransactions (claimId) {
		return httpClient(`/api/claims/${claimId}/payment-transactions`, { omitSpinner: true })
			.get()
			.then(toPaymentTransactions);
	},
	getPaymentMethods (brandId) {
		return httpClient(`/api/claims/payments/methods?brandId=${brandId}`)
			.get();
	},
	getAllPaymentMethods (locale) {
		return httpClient(`/api/claims/payments/methods/all?locale=${locale}`)
			.get();
	},
	saveClaimItems (claimId, claimItems, saveAsDraft = false, ignoreNoPolicyLimits = false, ignoreDuplicateInvoice = false, ignoreDuplicateClaim = false, reassessmentOutcomeId = null, rejectionData = {
		isManuallyRejected: false,
		rejectionReasonId: null,
		comment: null,
	},
	fraudCheckData = {
		fraudCheck: false,
		fraudCheckReasons: [],
		fraudCheckComment: null,
	}) {
		return httpClient(`/api/claims/${claimId}/claim-items`)
			.post({
				claimItems: claimItems.map((item) => toClaimItemInput(item)),
				saveAsDraft,
				rejectionData,
				ignoreNoPolicyLimits,
				ignoreDuplicateInvoice,
				ignoreDuplicateClaim,
				reassessmentOutcomeId,
				...fraudCheckData,
			});
	},
	getClaimItems (claimId) {
		return httpClient(`/api/claims/${claimId}/claim-items`)
			.get()
			.then(toClaimItemsWithStatus);
	},
	getPaymentDetails (claimId) {
		return httpClient(`/api/claims/${claimId}/payment-details`)
			.get()
			.then(toPaymentDetails);
	},
	createPaymentAccount (data) {
		return httpClient('/api/claims/payments/account')
			.post(data);
	},
	getClaimPaymentSummary (claimId) {
		return httpClient(`/api/claims/${claimId}/payments/policy-sections-summaries`)
			.get()
			.then(toClaimItemSummaryView);
	},
	finishClaim (claimId, data, saveAsDraft = false, reassessmentOutcomeId = null, excludedEligibleConditionIds = [], rejectionData = {
		isManuallyRejected: false,
		rejectionReasonId: null,
		comment: null,
	}, fraudCheck = false, fraudCheckReasons = [], fraudCheckComment = null) {
		return httpClient(`/api/claims/${claimId}/payments`)
			.post({ claimPaymentModels: data, saveAsDraft, rejectionData, fraudCheck, fraudCheckReasons, fraudCheckComment, reassessmentOutcomeId, excludedEligibleConditionIds });
	},
	getPetClaims (petId) {
		return httpClient(`/api/claims/pets/${petId}/claims`)
			.get()
			.then(toPetClaimsData);
	},
	getClaimItemsFromVetInvoices (claimId, data) {
		return httpClient(`/api/claims/${claimId}/convert-to-claim-items`)
			.post(data)
			.then(claimItems => claimItems.map(fromScannedtoClaimItemView));
	},
	getPredictedClaimAmount (claimData) {
		return httpClient('/api/machine-learning/prediction/claim-amount')
			.post(claimData);
	},
	reassessClaim (claimId, reassessmentReason) {
		return httpClient(`/api/claims/${claimId}/reassess`)
			.post(reassessmentReason);
	},
	reassessPendingClaim (claimId, handleErrorCodes = []) {
		return httpClient(`/api/claims/${claimId}/reassess-pending`, { handleErrorCodes })
			.post();
	},
	restoreClaim (claimId) {
		return httpClient(`/api/claims/${claimId}/restore`)
			.post();
	},
	validatePolicy (petId, treatmentStartDate, dateOfLoss, conditionTypeId) {
		const localTreatmentStartDate = new LocalDate(treatmentStartDate);
		const localDateOfLoss = new LocalDate(dateOfLoss);
		let queryParam = `?treatmentStartDate=${localTreatmentStartDate.toJSON()}&dateOfLoss=${localDateOfLoss.toJSON()}`;

		if (conditionTypeId) {
			queryParam += `&conditionTypeId=${conditionTypeId}`;
		}

		return httpClient(`/api/claims/pets/${petId}/validate-policy${queryParam}`)
			.get();
	},
	getParentPetClaims (petId, dateOfLoss, treatmentStartDate) {
		const params = {
			dateOfLoss: new LocalDate(dateOfLoss).toJSON(),
			treatmentStartDate: new LocalDate(treatmentStartDate).toJSON(),
		};

		return httpClient(`/api/claims/pets/${petId}/parent-claims?${getQueryString(params)}`)
			.get()
			.then(toParentPetClaim);
	},
	validateDateOfLoss (parentClaimIds, dateOfLoss, ignoredClaimId) {
		const localDateOfLoss = new LocalDate(dateOfLoss);
		let url = `/api/claims/date-of-loss?dateOfLoss=${localDateOfLoss.toJSON()}&ignoredClaimId=${ignoredClaimId}`;

		parentClaimIds.forEach(id => {
			url = url + `&parentClaimIds=${id}`;
		});

		return httpClient(url)
			.get();
	},
	getAdjusterComment (claimId) {
		return httpClient(`/api/claims/${claimId}/adjuster-comment`)
			.get();
	},
	saveAdjusterComment (claimId, comment) {
		return httpClient(`/api/claims/${claimId}/adjuster-comment`)
			.put({ comment });
	},
	generateHospitalNotification (claimId, message) {
		return httpClient(`/api/hospital/claims/${claimId}/notification`)
			.post({ message });
	},
	getClaimRejectionReasons (claimId, parameters) {
		const payload = {
			...parameters,
			...parameters.dateOfLoss && { dateOfLoss: new LocalDate(parameters.dateOfLoss) },
			...parameters.treatmentStartDate && { treatmentStartDate: new LocalDate(parameters.treatmentStartDate) },
			...parameters.treatmentEndDate && { treatmentEndDate: new LocalDate(parameters.treatmentEndDate) },
		};
		return httpClient(`/api/rejection/${claimId}/reasons`)
			.post(payload);
	},
	getClaimRejectionReason (claimId) {
		return httpClient(`/api/rejection/${claimId}/reason`)
			.get();
	},
	editPayment (paymentId, paymentAccountId) {
		return httpClient(`/api/claims/payments/${paymentId}`)
			.put({ paymentAccountId });
	},
	flagFastTrack (claimId, reasonId) {
		return httpClient(`/api/claims/${claimId}/fast-track-review`)
			.put({ reasonId });
	},
	getFastTrackReasons () {
		return httpClient('/api/claims/fast-track/reasons')
			.get();
	},
	getClaimVersions (claimId) {
		return httpClient(`/api/claims/${claimId}/versions`)
			.get();
	},
	getRecentlyViewedClaims () {
		return httpClient('/api/claims/recently-viewed')
			.get();
	},
	escalate (claimId, reasonId) {
		return httpClient(`/api/claims/${claimId}/escalate`)
			.post({ reasonId });
	},
	removeEscalation (claimId) {
		return httpClient(`/api/claims/${claimId}/remove-escalation`).post();
	},
	stopAndReissuePayment (claimPaymentId, paymentAccountId) {
		return httpClient(`/api/claims/payments/${claimPaymentId}/stop-and-reissue`)
			.post({ paymentAccountId });
	},
};
