import httpClient from './httpClient';
import { getWorkQueueModelConverter, toClaimStatistics, toTasksTableModel } from '@commonServices/utils/workflowConverter';
import extraService from './extraService';
import { FilterType } from './models/FilterType';
import { FilterGroupEnum } from './models/FilterGroup';
import { WorkQueueType } from './models/WorkQueueType';
import ErrorCode from './models/ErrorCode';
import exportService from './exportService';

const WorkQueueFilterGroupMap = {
	[WorkQueueType.DraftClaims]: FilterGroupEnum.WorkQueueDraft,
	[WorkQueueType.PaymentApproval]: FilterGroupEnum.WorkQueueSettled,
	[WorkQueueType.FraudCheck]: FilterGroupEnum.WorkQueueFraudCheck,
	[WorkQueueType.RejectionApproval]: FilterGroupEnum.WorkQueueRejectionPending,
	[WorkQueueType.Chasing]: FilterGroupEnum.WorkQueueMissingInfo,
	[WorkQueueType.ReassessmentPending]: FilterGroupEnum.WorkQueueReassessmentPending,
};

const basePath = '/api/claims/work-queues';
export default {
	getClaimsStatistics () {
		return httpClient(`${basePath}/statistics`)
			.get()
			.then(toClaimStatistics);
	},
	getWorkQueueData (pot, paging, filters = [], sorting = []) {
		return httpClient(`${basePath}/${pot}?${paging.toQuery()}`)
			.post({ filters, sorting })
			.then(getWorkQueueModelConverter(pot));
	},
	getRestWorkQueueData (pot, paging) {
		return httpClient(`${basePath}/${pot}/rest?${paging.toQuery()}`)
			.get()
			.then(getWorkQueueModelConverter(pot));
	},
	rejectSettledClaim (claimId, rejectionMessage, handleErrorCodes = []) {
		return httpClient(`${basePath}/reject/payment-approval?claimId=${claimId}`, { handleErrorCodes })
			.post(JSON.stringify(rejectionMessage));
	},
	approveSettledClaim (claimId, handleErrorCodes = []) {
		return httpClient(`${basePath}/approve/payment-approval?claimId=${claimId}`, { handleErrorCodes })
			.post();
	},
	approvePendingClaim (claimId, handleErrorCodes = [], reassessmentOutcomeId = null) {
		return httpClient(`${basePath}/approve/rejection-approval?claimId=${claimId}`, { handleErrorCodes })
			.post(reassessmentOutcomeId);
	},
	rejectPendingClaim (claimId, rejectionMessage, handleErrorCodes = []) {
		return httpClient(`${basePath}/reject/rejection-approval?claimId=${claimId}`, { handleErrorCodes })
			.post(JSON.stringify(rejectionMessage));
	},
	approveFraudCheckClaim (claimId, handleErrorCodes = []) {
		return httpClient(`${basePath}/approve/fraud-check?claimId=${claimId}`, { handleErrorCodes })
			.post();
	},
	rejectFraudCheckClaim (claimId, rejectionMessage, reassessmentOutcomeId, handleErrorCodes = []) {
		return httpClient(`${basePath}/reject/fraud-check?claimId=${claimId}`, { handleErrorCodes })
			.post({ rejectionMessage, reassessmentOutcomeId });
	},
	chase (claimId, customerId, handleErrorCodes = []) {
		return httpClient(`${basePath}/${claimId}/chase?customerId=${customerId}`, { handleErrorCodes })
			.post()
			.then(result => result.value);
	},
	getFiltersLookupData (pot) {
		const filterTypes = [
			FilterType.PetType, FilterType.ClaimType,
			FilterType.ClaimFormType, FilterType.SettledReason,
			FilterType.PolicySection, FilterType.EscalationType,
			FilterType.LanguagePreference,
			FilterType.Brand,
			FilterType.County,
		];
		if (pot === WorkQueueType.Chasing) {
			filterTypes.push(FilterType.MissingItem);
		}
		if (pot === WorkQueueType.FraudCheck) {
			filterTypes.push(FilterType.FraudFlag);
		}
		if (pot === WorkQueueType.RejectionApproval) {
			filterTypes.push(FilterType.RejectionReason, FilterType.RejectionStatus);
		}
		if (pot === WorkQueueType.DraftClaims) {
			filterTypes.push(FilterType.ConditionType, FilterType.ClaimType, FilterType.ReassessmentReason, FilterType.Country, FilterType.PaymentRecipient);
		}

		return extraService.getFiltersData(filterTypes);
	},
	getQueues (pot) {
		const group = WorkQueueFilterGroupMap[pot];
		return httpClient(`${basePath}/${group}/queues`)
			.get();
	},
	saveQueue (pot, queueModel, handleErrorCodes = []) {
		const group = WorkQueueFilterGroupMap[pot];
		return httpClient(`${basePath}/${group}/queues`, { handleErrorCodes })
			.post(queueModel);
	},
	getQueue (queueId) {
		return httpClient(`${basePath}/queues/${queueId}`)
			.get();
	},
	updateQueue (queueId, filters) {
		return httpClient(`${basePath}/queues/${queueId}`)
			.put(filters);
	},
	updateQueueDetails (queueId, name, handleErrorCodes = []) {
		return httpClient(`${basePath}/queues/${queueId}/details`, { handleErrorCodes })
			.put({ name });
	},
	deleteQueue (queueId) {
		return httpClient(`${basePath}/queues/${queueId}`)
			.delete();
	},
	getAllQueues () {
		return httpClient('/api/claims/teams/queues')
			.get();
	},
	assignTask (pot, claimIds, user, queueId) {
		return httpClient(`${basePath}/assign/${pot}`)
			.post({ claimIds, user, queueId });
	},
	unAssignTask (pot, claimIds) {
		return httpClient(`${basePath}/assign/${pot}`)
			.delete(claimIds);
	},
	getTasks () {
		return httpClient(`${basePath}/tasks`)
			.get()
			.then(toTasksTableModel);
	},
	getTask () {
		return httpClient(`${basePath}/tasks/next`)
			.post();
	},
	getTaskAutomatic () {
		return httpClient(`${basePath}/tasks/next/automatic`, { omitSpinner: true, handleErrorCodes: [ErrorCode.UserHasMaxTasks] })
			.post();
	},
	getQueuesUsersStatistics () {
		return httpClient(`${basePath}/automatic/statistics`)
			.get();
	},
	generateExportDocument (exportModel, fileName) {
		return exportService.exportDocument(httpClient, exportModel, fileName, `${basePath}/export`);
	},
	acknowledgeTask (claimId) {
		return httpClient(`${basePath}/tasks/${claimId}/acknowledge`)
			.post();
	},
};
