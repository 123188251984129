<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			title="Group Claim"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="no-gutters">
						<div class="pb-12 text-bold fs-15">
							Please select a claim you wish to group to this current claim
						</div>
						<div>
							Current claim: <strong>{{ this.claimId | claimIdFormat }}</strong>
						</div>
						<div class="my-3 claim-state">
							<div class="claim-state-element-container">
								{{ dateOfLossLabel }}:
								<div class="claim-state-element-value">
									{{ currentClaimDateOfLoss || 'No Value' }}
								</div>
							</div>
							<div class="claim-state-element-container">
								Diagnosis:
								<div
									class="claim-state-element-value"
									v-tooltip="currentClaimAilment"
								>
									{{ currentClaimAilment || 'No Value' }}
								</div>
							</div>
							<div class="claim-state-element-container">
								Body part:
								<div class="claim-state-element-value">
									{{ currentClaimBodypart || 'No Value' }}
								</div>
							</div>
						</div>
						<div class="pb-12">
							<div class="items-list scrollbar">
								<parent-claims-table-card
									label="Suggested parents"
									:multicondition="multicondition"
									:date-of-loss="dateOfLoss"
									:parent-claims="suggestedParentPetClaims"
									:claim-condition="claimCondition"
									:selected-claim-condition-id="selectedClaimConditionId"
									:marked-parent-condition-ids="markedParentConditionIds"
									@claim-selected="onSelectClaim"
								/>
								<parent-claims-table-card
									label="Other parent options"
									:multicondition="multicondition"
									:date-of-loss="dateOfLoss"
									:parent-claims="this.parentPetClaims.filter(item => !item.isPartialMatch)"
									:claim-condition="claimCondition"
									:selected-claim-condition-id="selectedClaimConditionId"
									:marked-parent-condition-ids="markedParentConditionIds"
									@claim-selected="onSelectClaim"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						data-qa="continuationClaimModal_button_cancel"
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						data-qa="continuationClaimModal_button_groupSelected"
						@click="onContinue"
						:disabled="parentPetClaims.length === 0 || !selectedClaimConditionId"
						type="button"
						class="btn btn-success ml-auto"
					>
						Group Claims
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ClaimService from '@commonServices/claimService';
import { shortDate } from '@commonServices/utils/filters';
import { areDatesEqual } from '@commonServices/utils/dateUtils';
import ParentClaimsTableCard from './ContinuationClaimModal/ParentClaimsTableCard';
import { ClaimFormType } from '@commonServices/models/ClaimFormType';

export default {
	components: { ParentClaimsTableCard },
	data () {
		return {
			parentPetClaims: [],
			selectedClaimConditionId: null,
			selectedClaimCondition: null,
		};
	},
	props: {
		petId: {
			type: Number,
			required: true,
		},
		dateOfLoss: {
			type: Date,
			required: false,
			default: null,
		},
		treatmentStart: {
			type: Date,
			required: false,
			default: null,
		},
		multicondition: {
			type: Boolean,
			required: false,
			default: false,
		},
		claimId: {
			type: [String, Number],
			required: true,
		},
		claimCondition: {
			type: Object,
			required: true,
		},
		markedParentConditionIds: {
			type: Array,
			required: false,
			default: () => [],
		},
		formType: {
			type: Number,
			required: true,
		},
	},
	async mounted () {
		this.parentPetClaims = (await ClaimService
			.getParentPetClaims(this.petId, this.dateOfLoss, this.treatmentStart))
			.filter(item => this.claimId !== item.claimId && (item.formType === this.formType
			|| (this.appSettings.extendedContinuationParenting && item.formType !== ClaimFormType.OtherLosses && this.formType !== ClaimFormType.OtherLosses)))
			.map((item) => {
				const matches = this.getMatches(item);
				return {
					...item,
					isPartialMatch: this.isPartialMatch(matches),
					isFullMatch: this.isFullMatch(matches),
					matches,
					ailment: {
						...item.ailment,
						conditionTypes: [item.conditionType],
					},
				};
			})
			.sort((first, second) => {
				return first.dateOfLoss > second.dateOfLoss;
			});
	},
	methods: {
		onContinue () {
			this.$emit('continuation-claim', this.selectedClaimCondition);
		},
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		onSelectClaim (claimCondition) {
			if (this.selectedClaimConditionId === claimCondition.conditionId) {
				this.selectedClaimConditionId = null;
				this.selectedClaimCondition = null;
			} else {
				this.selectedClaimConditionId = claimCondition.conditionId;
				this.selectedClaimCondition = claimCondition;
			}
		},
		isPartialMatch (matches) {
			return matches.length > 0;
		},
		isFullMatch (matches) {
			return matches.length === 3;
		},
		getMatches (claim) {
			const matches = [];

			if (claim.ailment.firstCause === this.currentClaimAilment) {
				matches.push(claim.ailment.firstCause);
			}
			if (claim.bodyPart?.description === this.currentClaimBodypart) {
				matches.push(claim.bodyPart?.description);
			}
			if (areDatesEqual(claim.dateOfLoss, this.dateOfLoss)) {
				matches.push(shortDate(claim.dateOfLoss));
			}

			return matches;
		},
	},
	computed: {
		...mapState(['appSettings']),
		...mapGetters(['dateOfLossLabel']),
		currentClaimAilment () {
			return this.claimCondition?.selectedAilment?.firstCause;
		},
		currentClaimBodypart () {
			return this.claimCondition?.selectedBodyPart?.description;
		},
		currentClaimDateOfLoss () {
			return shortDate(this.dateOfLoss) || null;
		},
		suggestedParentPetClaims () {
			return this.parentPetClaims
				.filter(item => item.isPartialMatch)
				.sort((first) => {
					return first.isFullMatch ? -1 : 1;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.claim-state {
  background-color: var(--backgroundFour);
  min-height: 40px;
  display: flex;
  align-items: center;
}

.claim-state-element-container {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}

.claim-state-element-value {
  background-color: var(--currentClaimAttributeBg);
  margin-left: 3px;
  padding: 3px 4px;
  border-radius: 3px;
  max-width: 350px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.items-list.scrollbar {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 5px;
}

.modal-content {
  width: 940px;
}
</style>
