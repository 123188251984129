<template>
	<div
		data-qa="parentClaimsTableCard_card_parentClaimsInfo"
		class="parent-claims-table-wrapper"
	>
		<div class="parent-claims-table-container">
			<div class="parent-claims-table-label">
				{{ label }}
			</div>
			<el-table
				empty-text="There are no Claim groups to select"
				:data="parentClaims"
				style="width: 100%;"
				class="w-100"
				:class="areMarkersNeeded ? 'with-markers' : ''"
				:row-class-name="tableRowClassName"
				cell-class-name="py-10"
			>
				<el-table-column
					label="ID"
					width="80"
				>
					<template slot-scope="scope">
						<div
							v-if="scope.row.isPartialMatch"
							class="info-icon-anchor"
						>
							<v-popover
								placement="right"
								trigger="hover"
								class="info-icon-popover"
								:popover-inner-class="`info-icon-popover-inner ${getMatchClass(scope.row)}`"
								popover-arrow-class="info-icon-popover-arrow"
								popover-base-class="info-icon-popover-base"
							>
								<div>
									<div :class="`fa-solid aqv-info-circle info-icon ${getMatchClass(scope.row)}`" />
								</div>
								<template slot="popover">
									<div class="fs-13 text-white">
										{{ scope.row.matches.filter(Boolean).join(', ') }}
									</div>
								</template>
							</v-popover>
						</div>
						<div>{{ scope.row.claimId | claimIdFormat }}</div>
					</template>
				</el-table-column>
				<el-table-column
					:label="dateOfLossLabel"
					width="110"
				>
					<template slot-scope="scope">
						<div>
							{{ scope.row.dateOfLoss | shortDate }}
						</div>
					</template>
				</el-table-column>
				<el-table-column
					label="Diagnosis"
					min-width="130"
				>
					<template slot-scope="scope">
						<truncated-text-cell :value="scope.row.ailment.firstCause" />
					</template>
				</el-table-column>
				<el-table-column
					label="Treatment Start"
					width="110"
				>
					<template slot-scope="scope">
						<div>
							{{ scope.row.treatmentStart | shortDate }}
						</div>
					</template>
				</el-table-column>
				<el-table-column
					label="Treatment End"
					width="110"
				>
					<template slot-scope="scope">
						<div>
							{{ scope.row.treatmentEnd | shortDate }}
						</div>
					</template>
				</el-table-column>
				<el-table-column
					label="Body Part"
					width="110"
				>
					<template slot-scope="scope">
						<truncated-text-cell :value="scope.row.bodyPart?.description || 'N/A'" />
					</template>
				</el-table-column>
				<el-table-column
					width="40"
				>
					<template slot-scope="scope">
						<pre-authorisation-icon
							:form-type="scope.row.formType"
						/>
					</template>
				</el-table-column>
				<el-table-column width="150">
					<template slot-scope="scope">
						<button
							data-qa="parentClaimsTableCard_button_selectParent"
							type="button"
							:class="['btn w-100', selectedClaimConditionId === scope.row.conditionId ? 'btn-warning darken' : 'btn-primary' ]"
							:disabled="canBeParent(scope.row)"
							@click="onSelectClaim(scope.row)"
						>
							<div
								v-tooltip="errorMessage(scope.row)"
							>
								{{ selectedClaimConditionId === scope.row.conditionId ? 'Selected' : 'Select' }}
							</div>
						</button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PreAuthorisationIcon from '@commonView/Shared/PreAuthorisationIcon';

export default {
	name: 'ParentClaimsTableCard',
	components: {
		PreAuthorisationIcon,
	},
	props: {
		label: {
			type: String,
			required: true,
		},
		multicondition: {
			type: Boolean,
			required: false,
			default: false,
		},
		dateOfLoss: {
			type: Date,
			required: false,
			default: null,
		},
		parentClaims: {
			type: Array,
			required: false,
			default: () => [],
		},
		claimCondition: {
			type: Object,
			required: true,
		},
		selectedClaimConditionId: {
			type: Number,
			required: false,
			default: null,
		},
		markedParentConditionIds: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	methods: {
		onSelectClaim (claimCondition) {
			this.$emit('claim-selected', claimCondition);
		},
		tableRowClassName (rowObject) {
			return `list-item--bg ${this.getMatchClass(rowObject.row)}`;
		},
		getMatchClass (claim) {
			if (claim.isFullMatch) {
				return 'full-match';
			} else if (claim.isPartialMatch) {
				return 'partial-match';
			}

			return '';
		},
		canBeParent (claimCondition) {
			return this.multicondition
			&& !claimCondition.canBeParent
			&& this.markedParentConditionIds.some(conditionId => conditionId === claimCondition.conditionId);
		},
		errorMessage (claimCondition) {
			if (claimCondition.canBeParent) {
				return '';
			}

			if (this.multicondition && this.markedParentConditionIds.some(conditionId => conditionId === claimCondition.conditionId)) {
				return 'A condition in this claim is already marked as the parent condition';
			}

			return 'Current condition will become the parent condition';
		},
	},
	computed: {
		...mapGetters(['dateOfLossLabel']),
		areMarkersNeeded () {
			return this.parentClaims.some(claim => claim.isPartialMatch);
		},
	},
};
</script>

<style lang="scss" scoped>
.parent-claims-table-wrapper {
  padding-top: 20px;
}

.parent-claims-table-container {
  border: 2px solid var(--attributeBg);
  border-radius: 3px;
  position: relative;
  padding: 15px 15px 5px;
}

.parent-claims-table-label {
  background-color: var(--attributeBg);
  margin-left: 3px;
  padding: 3px 5px;
  border-radius: 3px;
  width: fit-content;
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
  font-weight: 600;
}

.info-icon-anchor {
  display: flex;
  max-height: 0;
  max-width: 0;

  .info-icon-popover {
    position: relative;
    top: -24px;
    left: -27px;
  }

  .info-icon {
    background: var(--contentBg);
    border-radius: 15px;
    font-size: 24px;
    z-index: 1001;
  }

  .info-icon-popover.open .partial-match.info-icon {
    color: #d5a80f;
  }

  .info-icon-popover.open .full-match.info-icon {
    color: #03aca0;
  }
}

::v-deep .with-markers .el-table__body {
  border-spacing: 0 10px + 3px;
}
</style>

<style lang="scss">
.full-match {
  &.el-table__row {
    box-shadow: 0 3px 0 0 #23cabe;
  }

  &.info-icon {
    color: #23cabe;
  }

  .info-icon:hover {
    color: #03aca0;
  }

  &.info-icon-popover-inner {
    background: #03aca0;
  }
}

.partial-match {
  &.el-table__row {
    box-shadow: 0 3px 0 0 #feca18;
  }

  &.info-icon {
    color: #feca18;
  }

  .info-icon:hover {
    color: #d5a80f;
  }

  &.info-icon-popover-inner {
    background: #d5a80f;
  }
}

.info-icon-popover-inner {
  border: none;
  border-radius: 0;
  padding: 0 5px 0 7px;
}

.info-icon-popover-arrow {
  display: none;
}

.info-icon-popover-base {
  margin-left: 0;
  z-index: 1000;
  left: -6px !important;
}

</style>
