export class ClaimDataItem {
	constructor (
		claimId,
		claimConditionId,
		treatmentStart,
		treatmentEnd,
		diagnosis,
		ailmentId,
		amount,
		paid,
		displayStatus,
		status,
		reassessmentDateTime,
		reassessmentReason,
		reassessmentReasonCode,
		wasReassessed,
		conditions,
		claimItems,
		createdDateTime,
		closedByUser,
		closedDateTime,
		assignedToUser,
		assignedToDateTime,
		assessedByUser,
		assessedDateTime,
		claimVets,
		formType,
		nextStatus,
		linkedClaims,
		source,
		description,
		payments,
		invoices,
		continuation,
		historical,
	) {
		this.claimId = claimId;
		this.claimConditionId = claimConditionId;
		this.treatmentStart = treatmentStart;
		this.treatmentEnd = treatmentEnd;
		this.diagnosis = diagnosis;
		this.ailmentId = ailmentId;
		this.amount = amount;
		this.paid = paid;
		this.displayStatus = displayStatus;
		this.status = status;
		this.reassessmentDateTime = reassessmentDateTime;
		this.reassessmentReason = reassessmentReason;
		this.reassessmentReasonCode = reassessmentReasonCode;
		this.wasReassessed = wasReassessed;
		this.conditions = conditions;
		this.claimItems = claimItems;
		this.createdDateTime = createdDateTime;
		this.closedByUser = closedByUser;
		this.closedDateTime = closedDateTime;
		this.assignedToUser = assignedToUser;
		this.assignedToDateTime = assignedToDateTime;
		this.assessedByUser = assessedByUser;
		this.assessedDateTime = assessedDateTime;
		this.claimVets = claimVets;
		this.formType = formType;
		this.nextStatus = nextStatus;
		this.linkedClaims = linkedClaims;
		this.source = source;
		this.description = description;
		this.payments = payments ?? [];
		this.invoices = invoices ?? [];
		this.continuation = continuation;
		this.historical = historical;
	}
}
