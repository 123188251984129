<template>
	<div class="w-100">
		<div
			class="toolbar d-flex align-items-center"
			:class="{ 'pre-auth': isPreAuthorisation, 'other-loss': isOtherLoss }"
		>
			<slot name="left" />
			<div class="flex-fill px-30">
				<template v-if="!isReadOnlyMode && $can.ClaimEditContinuation">
					<div
						class="my-15"
						v-if="claimData.isContinuation"
					>
						You are currently {{ claimData.isNewClaim ? 'creating' : 'editing' }} a continuation claim <strong>{{ claimData.claimId | claimIdFormat }}</strong> from {{ 'claim' | pluralize(parentClaimIds.length) }} <strong>{{ formattedParentClaimIds }}</strong>
					</div>
					<div
						class="my-15"
						v-if="!claimData.isContinuation && !claimData.isParent"
					>
						You are currently {{ claimData.isNewClaim ? 'creating' : 'editing' }} a new claim <strong>{{ claimData.claimId | claimIdFormat }}</strong>
					</div>
				</template>
				<template v-if="isReadOnlyMode">
					<div class="my-15">
						You are currently viewing claim <strong>{{ claimData.claimId | claimIdFormat }}</strong>
					</div>
				</template>
			</div>
			<div
				v-if="!finalClaimStatuses.includes(sharedData.claimStatus) && !claimData.isNewClaim"
				class="mb-15 mr-15"
			>
				<current-assignment
					:assignment="sharedData.claim.claimAssignment"
					:claim-id="claimData.claimId"
					:claim-status="sharedData.claim.status"
					@user-assigned="$emit('on-user-assigned')"
				/>
			</div>
			<slot name="right" />
		</div>
		<fast-track-reviewed-bar
			v-if="!claimData.isNewClaim"
			:claim-id="claimData.claimId"
			:is-fast-track="sharedData.isFastTrackReviewed"
			:disabled="!claimData.isFastTrackBarEnabled"
			@complete-fast-track="onCompleteFastTrack"
			data-qa="aqSteps_checkbox_fastTrack"
		/>
		<div
			v-else
			class="col-12 bar"
		/>
	</div>
</template>

<script>
import { claimIdFormat } from '@commonServices/utils/filters';
import { claimNumberStatus, finalClaimStatuses } from '@commonServices/models/ClaimStatusActionEnum';
import CurrentAssignment from '@commonView/ClaimPage/ClaimEnter/CurrentAssignment';
import FastTrackReviewedBar from '@commonView/ClaimPage/FastTrackReviewedBar';
import { ClaimFormType } from '@commonServices/models/ClaimFormType';
import { unique } from '@commonServices/utils/general';
import { mapState } from 'vuex';

export default {
	name: 'ClaimBanner',
	components: {
		CurrentAssignment,
		FastTrackReviewedBar,
	},
	data () {
		return {
			finalClaimStatuses,
			claimNumberStatus,
			claimIdFormat,
		};
	},
	props: {
		sharedData: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapState(['claimFormType']),
		isReadOnlyMode () {
			return this.sharedData.isReadOnlyMode
			|| ((this.claimData.isContinuation || this.claimData.isParent) && !this.$can.ClaimEditContinuation)
			|| !this.$can.ClaimEdit;
		},
		claimData () {
			return {
				claimId: this.sharedData.claim.claimId,
				isParent: this.sharedData.claim.isParent,
				isContinuation: !!this.parentClaimIds.length,
				isNewClaim: this.sharedData.claimStatus === claimNumberStatus.New,
				isFastTrackBarEnabled: !this.sharedData.isReassessment && this.sharedData.claimStatus === claimNumberStatus.Draft && !this.sharedData.isFastTrackReviewed && this.$can.FlagFastTrackReviewed,
			};
		},
		isPreAuthorisation () {
			return this.claimFormType?.id === ClaimFormType.PreAuthorisation;
		},
		isOtherLoss () {
			return this.claimFormType?.id === ClaimFormType.OtherLosses;
		},
		parentClaimIds: function () {
			const parentClaimIds = this.sharedData.conditions.filter(item => item.parentClaimId).map(item => item.parentClaimId);
			return unique(parentClaimIds);
		},
		formattedParentClaimIds () {
			return this.parentClaimIds.map((item) => claimIdFormat(item)).join(', ');
		},
	},
	methods: {
		onCompleteFastTrack () {
			this.sharedData.isFastTrackReviewed = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.toolbar {
  height: 58px;
  background: rgba($primary, 0.6);

  &.pre-auth {
    background: var(--bannerBgAlt);
    color: var(--sectionTextColour);
  }

  &.other-loss {
    background: var(--otherLossBg);
    color: var(--sectionTextColour);
  }
}

.bar {
  background-color: var(--fastTrackBarColor);
  height: 6px;
}
</style>
