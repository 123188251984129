<template>
	<div>
		<aq-confirmation-modal
			ref="continueDialog"
			name="continue-dialog"
			title="Confirm Change"
			description="You have unsaved changes which may influence the calculation of this claim. Any Subsequent data may be invalidated."
			yes-label="Save"
			no-label="Cancel"
		/>
		<aq-confirmation-modal
			ref="confirmRejection"
			name="confirm-rejection"
			title="Claim Rejection"
			description="Claim Items exist for this claim. By clicking on proceed the claim items will be deleted."
			yes-label="Proceed"
			no-label="Cancel"
		/>
		<aq-confirmation-modal
			ref="saveChangesDialog"
			name="save-changes-dialog"
			title="Save changes"
			description="You have unsaved changes, Click Save to Save as Draft or Cancel to Close without saving."
			yes-label="Save"
			no-label="Cancel"
		>
			<template
				v-if="!claimData.isAllowedToSaveClaimAsDraft"
				slot="yesButton"
			>
				<button
					type="button"
					class="btn btn-success ml-auto"
					disabled
				>
					<div
						v-tooltip="{
							content: 'Current claim status prevents saving as draft.',
							classes: 'not-allowed-save-draft-tooltip'
						}"
					>
						Save
					</div>
				</button>
			</template>
		</aq-confirmation-modal>
		<aq-confirmation-modal
			ref="changeDol"
			name="change-dol-dialog"
			:title="`Change ${dateOfLossLabel} on all claims?`"
			:description="`By changing the ${dateOfLossLabel} ALL claims within this group will be updated. Do you wish to continue?`"
			yes-label="Proceed"
			no-label="Revert"
		/>
		<aq-alert-modal
			ref="saveChangesAlert"
			name="save-changes-alert"
			title="Invalid data"
			description="Save aborted due to a validation error. Please correct it before saving"
		/>
		<aq-modal name="manual-reject">
			<manual-rejection-modal
				:rejection-context="rejectionContext"
				@reject-cancel="onManualRejectCancel"
				@reject="onManualReject"
			/>
		</aq-modal>
		<aq-confirmation-modal
			ref="dateOfDeathDialog"
			name="change-dod-dialog"
			title="Date of Death"
			description="do not show"
			yes-label="Proceed"
			no-label="Revert"
			v-if="interactionContext.pet"
		>
			<template>
				<div class="p-22 text-center">
					<div class="pet-alert-logo">
						<aq-pet-avatar
							class="large"
							:pet="interactionContext.pet"
						/>
					</div>
					<div>
						The date of death previously recorded is:
					</div>
					<strong data-qa="enterBasic_dod_modal_prev_date">{{ shortDateOrNoDate(interactionContext.pet.dateOfDeath) }}</strong>
					<div>
						Do you wish to proceed updating the current Date of Death to:
					</div>
					<strong data-qa="enterBasic_dod_modal_to_date">{{ shortDateOrNoDate(basic.dateOfDeath) }}</strong> or revert back to <strong data-qa="enterBasic_dod_modal_revert_date">{{ shortDateOrNoDate(interactionContext.pet.dateOfDeath) }}</strong>
				</div>
			</template>
		</aq-confirmation-modal>
		<reassessment-outcome-modal
			v-if="claimId"
			data-qa="claimEnterBasic_modal_reassessmentOutcomeModal"
			name="reassessment-outcome-modal"
			ref="saveReassessmentOutcomeModal"
			:selected-reassessment-outcome="selectedReassessmentOutcome"
			:claim-id="claimId"
			@input="selectedReassessmentOutcome = $event"
		/>
		<aq-modal
			name="continuation-claim"
			v-if="claimId"
		>
			<continuation-claim-modal
				@continuation-claim="onBindClaim"
				:pet-id="petId"
				:date-of-loss="basic.dateOfLoss"
				:treatment-start="basic.treatmentStart"
				:multicondition="multicondition"
				:claim-id="claimId"
				:claim-condition="conditions[selectedConditionIndex]"
				:marked-parent-condition-ids="parentClaimConditionIds"
				:form-type="selectedClaimFormType.id"
			/>
		</aq-modal>
		<aq-modal
			name="fraud-check"
			v-if="claimId"
		>
			<fraud-check-modal
				@fraud-check="onFraudCheck"
				:claim-id="claimId"
			/>
		</aq-modal>
		<aq-confirmation-modal
			ref="confirmFormType"
			name="confirm-form-type"
			title="Confirm invoice has been received?"
			description="You are confirming the claims final invoice has been received.  Do you wish to proceed?"
			yes-label="Proceed"
			no-label="Cancel"
			data-qa="claimPage_modal_confirmFormType"
		/>
		<div
			class="container-fluid p-0"
			:class="[multicondition ? 'mb-45' : 'mb-85']"
		>
			<portal to="claim-banner-left">
				<claim-form-type-selector
					class="claim-form-type-selector"
					:value="selectedClaimFormType"
					:editable="claimFormTypeEditable"
					@input="onSelectClaimFormType"
				/>
			</portal>
			<portal
				to="claim-banner-right"
				v-if="!multicondition && !sharedData.isReadOnlyMode && $can.ClaimEditContinuation"
			>
				<button
					v-if="!multicondition && claimData.isContinuation"
					type="button"
					class="btn btn-primary btn-continuation"
					@click="onUnbindClaim(0)"
					data-qa="enterBasic_button_newClaim"
				>
					New Claim
				</button>
				<button
					v-if="!multicondition && !claimData.isContinuation && !claimData.isParent"
					type="button"
					class="btn btn-primary btn-continuation"
					@click="onContinuationClaim(0)"
					data-qa="enterBasic_button_continuationClaim"
				>
					Continuation Claim
				</button>
			</portal>
			<div
				v-if="claimId"
				class="row no-gutters"
			>
				<div class="claim-form w-100">
					<aq-fraud-check-actions
						v-if="claimData.isFraudCheck"
						:claim-id="claimId"
						:is-reassessment="sharedData.isReassessment"
						:has-claim-unsaved-changes="isStepDirty"
						:fraud-check-reasons="sharedData.fraudCheckReasons"
						@finish="onFraudChekActionFinish"
					/>
					<ClaimEnterFormSection
						data-qa="enterBasic_container_basic"
						:invalid="isBasicInValid"
						title="Basic Details"
						sub-title="Add the basic details of your claim"
					>
						<div class="container-fluid">
							<div class="row no-gutters">
								<div
									v-if="!isOtherLoss"
									class="col-12 d-flex position-relative align-items-center"
								>
									<payee-capture
										v-tooltip="preventBlocklistedVet && !this.isReadOnlyMode ? 'Vet unapproved or VDP is restricted' : ''"
										class="mb-16"
										:selected-payees="selectedPayees"
										:is-read-only-mode="payeeCaptureReadonly"
										:is-other-loss="isOtherLoss"
										:validator="$v"
										data-qa="enterBasic_input_payeeCapture"
										:ui-locale-settings="uiLocaleSettings"
										@input="onInputPayees"
									/>
								</div>
								<claim-basic
									:locale="locale"
									:basic="basic"
									:validator="$v.basic"
									:is-read-only-mode="isReadOnlyMode"
									:difference-amount="differenceAmount"
									:invoice-data="invoiceData"
									:labels="labels"
								/>
							</div>
						</div>
					</ClaimEnterFormSection>
					<ClaimEnterFormSection
						:class="[{ 'mb-65': isOtherLoss}]"
						data-qa="enterBasic_container_diagnosis"
						:invalid="isConditionsInvalid"
						:title="labels.diagnosis"
					>
						<claim-conditions
							ref="claimConditions"
							:shared-data="sharedData"
							:conditions="conditions"
							:is-read-only-mode="isReadOnlyMode"
							:multicondition="multicondition"
							:date-of-loss="basic.dateOfLoss"
							:treatment-start="basic.treatmentStart"
							:is-other-loss="isOtherLoss"
							:marked-parent-condition-ids="parentClaimConditionIds"
							:labels="labels"
							:date-of-loss-valid="!$v.basic.dateOfLoss.$invalid"
							:validator="$v.conditions"
							@continuation-claim="onContinuationClaim"
							@unbind-claim="onUnbindClaim"
							@bind-parent-condition="onBindParentCondition"
							@manual-reject="onManualRejectSelect"
							@manual-remove-reject="onManualRejectRemove"
							@override-rejection="overrideConditionRejection"
							@cancel-override="cancelOverrideConditionRejection"
						/>
					</ClaimEnterFormSection>
					<ClaimEnterFormSection
						v-if="!isOtherLoss"
						data-qa="enterBasic_container_vetDetails_details"
						title="Vet Details"
					>
						<claim-vets
							:selected-vets="selectedVets"
							:registered-vets="sharedData.registeredVets"
							:is-read-only-mode="isReadOnlyVetMode"
							:locale="locale"
							:validator="$v"
							@vets-changed="onVetsChange"
						/>
					</ClaimEnterFormSection>
				</div>
			</div>
		</div>
		<claim-navigation-bar
			:shared-data="sharedData"
			:status="status"
			:is-back-visible="false"
			:is-read-only-mode="isReadOnlyMode"
			:is-last-step="isLastStep"
			:multicondition="multicondition"
			:has-claim-items="originalStepData.hasClaimItems"
			:rejection-state="rejectionState"
			@next="onNext"
			@cancel="onCancel"
			@manual-reject="onManualRejectSelect"
			@manual-refine-reject="onManualRefineRejectSelect"
			@manual-remove-reject="onManualRejectRemove"
			@fraud="onFraudCheckSelect"
			@override-rejection="overrideConditionRejection"
			@cancel-override-condition="cancelOverrideConditionRejection"
			@refine-rejection="onRefineRejection"
			@escalate-claim="onClaimEscalateSelect"
			@remove-escalation="onRemoveEscalation"
		>
			<template slot="success">
				<div class="d-flex align-items-center mx-30">
					<div class="mx-auto">
						<div class="d-inline-block d-flex">
							<div><i class="mr-10 fas fa-check text-white" /></div>
							<div>You have completed all of the required fields. Please proceed to the next screen.</div>
						</div>
					</div>
					<div v-if="isMissingToggleAvailable">
						<aq-toggler
							data-qa="enterBasic_button_requestInfo"
							label="Generate request for information?"
							@input="isTogglerDirty=true"
							v-model="isMissingItemsToggled"
							:disabled="isReadOnlyMode || inRejectionState"
						/>
					</div>
				</div>
			</template>
			<template slot="incomplete">
				<div class="d-flex align-items-center mx-30">
					<div class="mx-auto">
						<div class="d-inline-block d-flex">
							Please complete all of the mandatory fields in order to proceed.
						</div>
					</div>
					<div v-if="isMissingToggleAvailable">
						<aq-toggler
							data-qa="enterBasic_button_requestInfo"
							label="Generate request for information?"
							@input="isTogglerDirty=true"
							v-model="isMissingItemsToggled"
							:disabled="isReadOnlyMode"
						/>
					</div>
				</div>
			</template>
			<template slot="warning">
				<div class="d-flex align-items-center mx-30">
					<div class="mx-auto">
						<div class="d-inline-block justify-content-center d-flex">
							<div><i class="mr-10 fas fa-exclamation-triangle text-white" /></div>
							<div>{{ rejectionMessage }}</div>
						</div>
						<aq-claims-list
							v-if="duplicateDataClaimIds"
							class="mt-5"
							label="Claim ID's"
							:claim-ids="duplicateDataClaimIds"
						/>
					</div>
					<div v-if="isMissingToggleAvailable">
						<aq-toggler
							data-qa="enterBasic_button_requestInfo"
							label="Generate request for information?"
							@input="isTogglerDirty=true"
							v-model="isMissingItemsToggled"
							:disabled="isReadOnlyMode"
							color="bg-warning"
						/>
					</div>
				</div>
			</template>
			<template slot="danger">
				<div class="d-flex align-items-center mx-30">
					<div class="mx-auto">
						<div class="d-inline-block d-flex">
							<div><i class="mr-10 fas fa-times-circle text-white" /></div>
							<div>The claim data is incomplete or has errors. Please review the data to proceed.</div>
						</div>
					</div>
				</div>
			</template>
		</claim-navigation-bar>
	</div>
</template>

<script>
import debounce from 'lodash.debounce';
import { and, helpers, maxLength, maxValue, minValue, required } from 'vuelidate/lib/validators';
import cloneDeep from 'lodash.clonedeep';
import { isKnownError, unique } from '@commonServices/utils/general';
import { fromStringToDate, areDatesEqual, addDays } from '@commonServices/utils/dateUtils';
import { lessThenOrEqual, maxAmount, moreThenOrEqual, moreThen, spaces } from '@commonServices/utils/validators';
import ClaimService from '@commonServices/claimService';
import ClaimInput from '@commonServices/models/ClaimInput';
import statusEnum from '@commonServices/models/steps/StepStatusEnum';
import StepNameEnum from '@commonServices/models/steps/StepNameEnum';
import { ClaimFormType, ClaimFormTypeLabels, ClaimFormTypeOptions } from '@commonServices/models/ClaimFormType';
import FraudCheckReason from '@commonServices/models/FraudCheckReason';
import FileService from '@commonServices/fileService';
import eventBus from '@commonServices/eventBus';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { toClaimDisplayStatus } from '@commonServices/utils/converter';
import ErrorCode from '@commonServices/models/ErrorCode';
import ContinuationClaimModal from '@commonView/ClaimPage/ContinuationClaimModal';
import { claimNumberStatus, finalClaimDisplayStatuses, outcomeState, saveClaimAsDraftAllowedStatuses } from '@commonServices/models/ClaimStatusActionEnum';
import { pageStatusEnum, invalidPageStatuses } from '@commonServices/models/PageStatusEnum';
import { shortDate, currency } from '@commonServices/utils/filters';
import ClaimConditions from './ClaimEnter/ClaimConditions';
import ClaimVets from './ClaimEnter/ClaimVets';
import ClaimFormTypeSelector from '@commonView/ClaimPage/ClaimEnter/ClaimFormTypeSelector';
import ClaimEnterFormSection from './ClaimEnter/ClaimEnterFormSection';
import ClaimBasic from './ClaimEnter/ClaimBasic';
import { toastActions } from '@commonServices/settings/toastSettings';
import { PayeeType } from '@commonServices/utils/payeeOptions';
import PayeeCapture from '@commonView/ClaimPage/ClaimEnter/PayeeCapture';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';
import { createCondition, getConditionOverrideOption, hasManualRejection, hasNoActiveCover, hasNoProductCover, hasOverride, hasPreEx, hasReleatedPreEx, hasWaitingPeriod, isConditionSystemRejected } from '@commonView/ClaimPage/ClaimEnter/claimUtils';
import VetService from '@commonServices/vetService';
import AqClaimsList from '@commonWidgets/AqClaimsList';
import { isHospitalSource } from '@clientCommon/services/models/ClaimSource';

const noActiveCoverPeriodMsg = 'Policy Warning - No Active Cover Period';
const rejPreExCondMsg = 'Claim Rejection - Pre-Existing Condition';
const rejRelCondMsg = 'Claim Rejection - Related Condition';
const rejMixedMsg = 'Claim Rejection - All Diagnoses are rejected';
const noProductCoverageMsg = 'Claim Rejection - No Product Coverage';
const rejManuallyMsg = 'Claim Rejection - Manual Rejection';
const noPolicyLimitsMsg = 'No Policy Limits available';
const duplicateClaimMsg = 'Claim Rejection - Duplicate Claim (Same date of loss, same amount)';
const duplicateInvoiceMsg = 'Claim Rejection - Duplicate Invoice';

const dateOfLossWithinWaitingPeriodMsgFactory = (dateOfLossLabel) => dateOfLossLabel + ' is within the Policy Wait Period.';
const dateOfLossBeforeWaitPeriodMsgFactory = (dateOfLossLabel) => dateOfLossLabel + ' is before the Policy Wait Period end date';
const dolPriorPetDOBMsgFactory = (dateOfLossLabel) => `Policy Warning - ${dateOfLossLabel} prior to pets DOB`;

const toastOptions = {
	icon: 'microchip',
	className: ['toast-alert'],
	action: toastActions.close,
};

const FORTNIGHT = 14;

export default {
	inject: ['goNext', 'goTo', 'exitClaim', 'changeVisibility', 'getStepData', 'mountClaimPage', 'updateClaimStatus', 'leavePage', 'onClaimEscalate'],
	components: {
		ContinuationClaimModal,
		ClaimConditions,
		ClaimVets,
		ClaimFormTypeSelector,
		PayeeCapture,
		ClaimEnterFormSection,
		ClaimBasic,
		AqClaimsList,
	},
	props: {
		sharedData: {
			type: Object,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		multicondition: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			isLoaded: false,
			claimNumberStatus,
			isOriginalData: true,
			isDraft: false,
			isProcessStepPassed: null,
			originalStepData: { basicData: { dateOfDeath: null } },
			selectedConditionIndex: 0,
			selectedVets: [],
			isAllDOLPriorPetDOBError: false,
			isUploadInProgress: false,
			isTogglerDirty: false,
			basic: {
				description: '',
				dateOfLoss: null,
				dateOfDeath: null,
				treatmentStart: null,
				treatmentEnd: null,
				amount: null,
			},
			conditions: [createCondition()],
			isMissingItemsToggled: false,
			areVetsDirty: false,
			isAllManuallyRejected: false,
			fraudCheck: false,
			fraudCheckReasons: [],
			fraudCheckComment: null,
			continuationGroupDateOfLoss: null,
			ignorePreExistingCondition: false,
			isContinuationDolValid: true,
			petId: null,
			claimId: null,
			differenceAmount: null,
			predictedClaimAmount: null,
			isAllNoActiveCoverPeriodError: false,
			isAllPreExistingConditionError: false,
			isAllWaitingPeriodError: false,
			waitingPeriodEndDate: null,
			isAllNoProductCoverageError: false,
			ignoreDuplicateClaim: false,
			ignoreDuplicateInvoice: false,
			duplicateClaimCheckResult: {
				isValid: true,
				claimIds: [],
			},
			duplicateInvoiceCheckResult: {
				isValid: true,
				claimIds: [],
			},
			isMixedRejection: false,
			currentRejectedConditionIndex: null,
			rejectionContext: {},
			selectedClaimFormType: { ...ClaimFormTypeOptions[0] },
			preAuthorisationCompleted: false,
			activeClaimAmountToast: null,
			selectedReassessmentOutcome: null,
			stepProceedAborted: false,
			refineRejection: false,
			selectedPayees: [],
			users: null,
			escalationReasonId: null,
			duplicateClaimIds: [],
		};
	},
	validations () {
		const requiredIfNotDraft = this.isDraft ? () => true : required;
		const requiredAndNoSpacesIfDraft = this.isDraft ? () => true : and(required, spaces);
		const moreThanZeroIfNotDraftOrIfPreAuth = this.isDraft || this.isPreAuthorisation ? minValue(0) : moreThen(0);
		const dateMaxValue = this.isPreAuthorisation ? () => true : maxValue(this.dateForFutureValidation); // disable 'dateInFuture' check for pre-authorisation claims

		const payeeMustBeSelected = this.isDraft ? () => true : (payees) => !helpers.req(payees) || payees.length > 0;
		const mustSelectVetsIfVetPayeeSelected = this.isDraft || !this.selectedPayees?.some(payee => payee.id === PayeeType.Vet) ? () => true : required;

		const requireIfNotOtherLossFormTypeAndNotDraft = this.isOtherLoss || this.isDraft ? () => true : required;

		return {
			basic: {
				description: {
					required: requiredAndNoSpacesIfDraft,
					maxLength: maxLength(2000),
				},
				dateOfLoss: {
					required: requiredIfNotDraft,
					maxValue: maxValue(this.dateForFutureValidation),
					minValue: moreThenOrEqual(this.minDate),
					isContinuationDolValid: () => this.isContinuationDolValid,
				},
				dateOfDeath: {
					maxValue: dateMaxValue,
					minValue: moreThenOrEqual(this.basic.dateOfLoss),
				},
				treatmentStart: {
					required: requiredIfNotDraft,
					maxValue: dateMaxValue,
					minValue: moreThenOrEqual(this.basic.dateOfLoss),
					deathValue: lessThenOrEqual(this.basic.dateOfDeath ? addDays(this.basic.dateOfDeath, FORTNIGHT) : null),
				},
				treatmentEnd: {
					required: requiredIfNotDraft,
					maxValue: dateMaxValue,
					treatmentStart: moreThenOrEqual(this.basic.dateOfLoss),
					minValue: moreThenOrEqual(this.basic.treatmentStart),
					deathValue: lessThenOrEqual(this.basic.dateOfDeath ? addDays(this.basic.dateOfDeath, FORTNIGHT) : null),
				},
				amount: {
					required: requiredIfNotDraft,
					minValue: moreThanZeroIfNotDraftOrIfPreAuth,
					maxAmount: maxAmount(16),
				},
			},
			conditions: {
				$each: {
					selectedAilment: {
						required: requiredIfNotDraft,
						uniqValue: function (value, condition) {
							return !value || !this.isDuplicateCondition(condition);
						},
					},
					selectedConditionType: {
						required: requiredIfNotDraft,
						uniqValue: function (value, condition) {
							return !value || !this.isDuplicateCondition(condition);
						},
					},
					selectedBodyPart: {
						required: requireIfNotOtherLossFormTypeAndNotDraft,
						uniqValue: function (value, condition) {
							return !value || !this.isDuplicateCondition(condition);
						},
					},
				},
			},
			selectedVets: {
				required: mustSelectVetsIfVetPayeeSelected,
			},
			selectedPayees: {
				required: requiredIfNotDraft,
				payeeMustBeSelected,
			},
		};
	},
	computed: {
		...mapState(['interactionContext', 'currentUser', 'appSettings', 'isOpenDocPanel']),
		...mapGetters(['dateOfLossLabel', 'dateForFutureValidation']),
		singlecondition () {
			return !this.multicondition;
		},
		isReadOnlyMode () {
			return this.sharedData.isReadOnlyMode
			|| ((this.claimData.isContinuation || this.claimData.isParent) && !this.$can.ClaimEditContinuation)
			|| !this.$can.ClaimEdit;
		},
		isPreAuthorisation () {
			return this.selectedClaimFormType.id === ClaimFormType.PreAuthorisation;
		},
		isOtherLoss () {
			return this.selectedClaimFormType.id === ClaimFormType.OtherLosses;
		},
		inRejectionStatus () { // based on claim status
			return [
				claimNumberStatus.RejectedWaitingPeriod,
				claimNumberStatus.RejectedByPreExistingCondition,
				claimNumberStatus.RejectedByRelatedCondition,
				claimNumberStatus.RejectedByNoActiveCoverPeriod,
				claimNumberStatus.RejectedByNoProductCoverage,
				claimNumberStatus.RejectedMultiple,
				claimNumberStatus.RejectedManually,
				claimNumberStatus.RejectionPending,
				claimNumberStatus.RejectedSectionLimit,
				claimNumberStatus.RejectedByConditionLimit,
				claimNumberStatus.RejectedByMultipleConditions,
				claimNumberStatus.RejectedByDuplicateClaim,
				claimNumberStatus.RejectedByDuplicateInvoice,
			].includes(this.sharedData.claimStatus);
		},
		inRejectionState () { // based on current claim UI state
			return this.isAllNoActiveCoverPeriodError
					|| this.isAllNoProductCoverageError
					|| this.isAllWaitingPeriodError
					|| this.isAllPreExistingConditionError
					|| this.isAllManuallyRejected
					|| this.isDuplicateClaim
					|| this.isDuplicateInvoice
					|| this.isMixedRejection;
		},
		rejectionMessage () {
			if ([this.sharedData.claimStatus, this.sharedData.nextStatus].includes(claimNumberStatus.RejectedManually) || this.isAllManuallyRejected) {
				return rejManuallyMsg;
			}

			if (this.isDuplicateInvoice || (this.claimData.isPendingRejection && this.sharedData.nextStatus === claimNumberStatus.RejectedByDuplicateInvoice)) {
				return duplicateInvoiceMsg;
			}

			if (this.isDuplicateClaim || (this.claimData.isPendingRejection && this.sharedData.nextStatus === claimNumberStatus.RejectedByDuplicateClaim)) {
				return duplicateClaimMsg;
			}

			if (this.sharedData.claimStatus === claimNumberStatus.RejectedByRelatedCondition
			|| (this.isAllPreExistingConditionError && this.conditions.every(cc => hasReleatedPreEx(cc, this.isReadOnlyMode)))) {
				return rejRelCondMsg;
			}

			if (this.sharedData.claimStatus === claimNumberStatus.RejectedByPreExistingCondition || this.isAllPreExistingConditionError) {
				return rejPreExCondMsg;
			}

			if (this.isAllDOLPriorPetDOBError) {
				return dolPriorPetDOBMsgFactory(this.dateOfLossLabel);
			}

			if (this.sharedData.claimStatus === claimNumberStatus.RejectedByNoActiveCoverPeriod || this.isAllNoActiveCoverPeriodError) {
				return noActiveCoverPeriodMsg;
			}

			if (this.sharedData.claimStatus === claimNumberStatus.RejectedByNoProductCoverage || this.isAllNoProductCoverageError) {
				return noProductCoverageMsg;
			}

			if (this.sharedData.claimStatus === claimNumberStatus.RejectedWaitingPeriod || this.isAllWaitingPeriodError) {
				return this.waitPeriodMsg;
			}

			if (this.sharedData.claimStatus === claimNumberStatus.RejectedMultiple || this.isMixedRejection) {
				return rejMixedMsg;
			}

			if (this.sharedData.claimStatus === claimNumberStatus.RejectedSectionLimit
				|| this.sharedData.claimStatus === claimNumberStatus.RejectedByConditionLimit
				|| this.sharedData.claimStatus === claimNumberStatus.RejectedByMultipleConditions
				|| (this.sharedData.claimStatus === claimNumberStatus.RejectionPending && (
					this.sharedData.nextStatus === claimNumberStatus.RejectedSectionLimit
				|| this.sharedData.nextStatus === claimNumberStatus.RejectedByConditionLimit
				|| this.sharedData.nextStatus === claimNumberStatus.RejectedByMultipleConditions
				))) {
				return noPolicyLimitsMsg;
			}

			return '';
		},
		isLastStep () {
			if (this.isReadOnlyMode) {
				return (this.inRejectionStatus || this.claimData.isFraudCheckFailed || this.inRejectionState) && !(this.originalStepData.hasClaimItems || this.originalStepData.hasPayments || this.sharedData.isReassessment);
			}
			return this.inRejectionState;
		},
		isMissingToggleAvailable () {
			if (this.isReadOnlyMode) {
				return true;
			}
			return this.claimData.isContinuation || this.claimData.isParent ? this.$can.EditMissingInformationContinuation : this.$can.EditMissingInformation;
		},
		isProcessStepValid: function () {
			return !(this.$v.$invalid || !this.isProcessStepPassed || this.isAffectProcessStep);
		},
		isAffectProcessStep: function () {
			if (this.originalStepData.hasClaimItems) {
				const conditionsChanged = this.originalStepData.conditions.length !== this.conditions.length
				|| this.originalStepData.conditions.some((originalCondition, index) => {
					const condition = this.conditions[index];
					return !(originalCondition.ailment?.id === condition.selectedAilment?.id
				&& originalCondition.conditionType?.id === condition.selectedConditionType?.id
				&& originalCondition.bodyPart?.id === condition.selectedBodyPart?.id
				&& originalCondition.wasIgnoredNoCoverPeriod === condition.ignoreNoCoverPeriod
				&& originalCondition.wasIgnoredNoProductCoverage === condition.ignoreNoProductCoverage
				&& originalCondition.wasIgnoredPreExistingCondition === condition.ignorePreExistingCondition
				&& originalCondition.wasIgnoredWaitingPeriod === condition.ignoreWaitingPeriod);
				});

				return !areDatesEqual(this.originalStepData.basicData.dateOfLoss, this.basic.dateOfLoss)
				|| !areDatesEqual(this.originalStepData.basicData.treatmentStart, this.basic.treatmentStart)
				|| !areDatesEqual(this.originalStepData.basicData.treatmentEnd, this.basic.treatmentEnd)
				|| this.originalStepData.basicData.amount !== this.basic.amount
				|| conditionsChanged;
			}
			return false;
		},
		isAffectOtherSteps: function () {
			return this.isAffectProcessStep
				|| (this.isMissingItemsToggled !== this.originalStepData.isRequestTriggered && this.originalStepData.hasMissingItems)
				|| (this.isVetRemoved && (this.originalStepData.hasMissingItems || this.originalStepData.hasPayments));
		},
		isStepDirty: function () {
			return this.$v.$anyDirty || this.areVetsDirty || this.isTogglerDirty;
		},
		isBasicInValid: function () {
			return (
				this.$v.basic.description.$error
				|| this.$v.basic.dateOfLoss.$error
				|| this.$v.basic.dateOfDeath.$error
				|| this.$v.basic.treatmentStart.$error
				|| this.$v.basic.treatmentEnd.$error
				|| this.$v.basic.amount.$error
			);
		},
		isConditionsInvalid: function () {
			return this.$v.conditions.$error;
		},
		isPayeesInvalid () {
			return this.$v.selectedPayees.$error;
		},
		isVetsInvalid () {
			return this.$v.selectedVets.$error;
		},
		isAnyError: function () {
			return this.isBasicInValid
				|| this.isConditionsInvalid
				|| this.isPayeesInvalid
				|| this.isVetsInvalid;
		},
		status: function () {
			if ((this.isReadOnlyMode && this.inRejectionStatus)
				|| this.inRejectionState) {
				return pageStatusEnum.Warning;
			}
			if (this.isAnyError) return pageStatusEnum.Danger;
			if (!this.$v.$invalid) return pageStatusEnum.Success;
			return pageStatusEnum.Incomplete;
		},
		isVetRemoved: function () {
			return this.originalStepData.vets
				&& this.originalStepData.vets.length > 0
				&& !this.originalStepData.vets.every(ov => this.selectedVets.some(sv => sv.id === ov.id));
		},
		isAnyFieldPopulated () {
			const isBasicFieldsPopulated = Object.values(this.basic).map((val) => {
				if (!isNaN(parseFloat(val))) {
					return parseFloat(val);
				} else return val;
			})
				.some(Boolean);
			const isVetsPopulated = this.selectedVets && this.selectedVets.length > 0;
			const isConditionsPopulated = this.conditions.some(c => !!c.selectedAilment || !!c.selectedConditionType || !!c.selectedBodyPart);
			return isBasicFieldsPopulated
				|| isConditionsPopulated
				|| isVetsPopulated;
		},
		parentClaimIds () {
			const parentClaimIds = this.conditions.filter(item => item.parentClaimId).map(item => item.parentClaimId);
			return unique(parentClaimIds);
		},
		parentClaimConditionIds () {
			const parentConditionIds = this.conditions.filter(item => item.parentConditionId).map(item => item.parentConditionId);
			return unique(parentConditionIds);
		},
		claimFormTypeEditable () {
			return !(this.isReadOnlyMode || this.preAuthorisationCompleted || this.sharedData.isReassessment);
		},
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
		waitPeriodMsg () {
			if (this.singlecondition && this.waitingPeriodEndDate) {
				return `${dateOfLossBeforeWaitPeriodMsgFactory(this.dateOfLossLabel)} ${shortDate(this.waitingPeriodEndDate)}.`;
			} else {
				return dateOfLossWithinWaitingPeriodMsgFactory(this.dateOfLossLabel);
			}
		},
		rejectionState () {
			return {
				initialStep: true,
				hasSystemRejection: this.isAllNoActiveCoverPeriodError || this.isAllWaitingPeriodError || this.isAllNoProductCoverageError || this.isAllPreExistingConditionError || this.isMixedRejection || this.isDuplicateClaim || this.isDuplicateInvoice,
				overrideOption: this.singlecondition ? getConditionOverrideOption(this.conditions[0]) : null,
				isManuallyRejected: this.isAllManuallyRejected,
				hasRejectionOverride: this.ignoreDuplicateClaim || this.ignoreDuplicateInvoice || this.conditions.some(cc => hasOverride(cc)),
				isDuplicateClaim: this.isDuplicateClaim,
				isDuplicateInvoice: this.isDuplicateInvoice,
				hasDuplicateOverride: this.ignoreDuplicateClaim || this.ignoreDuplicateInvoice,
			};
		},
		claimData () {
			return {
				isParent: this.sharedData.claim.isParent,
				isContinuation: !!this.parentClaimIds.length,
				isPendingRejection: this.sharedData.claimStatus === claimNumberStatus.RejectionPending,
				isFraudCheck: this.sharedData.claimStatus === claimNumberStatus.FraudCheck,
				isFraudCheckFailed: this.sharedData.claimStatus === claimNumberStatus.FraudCheckFailed,
				isAllowedToSaveClaimAsDraft: saveClaimAsDraftAllowedStatuses.includes(this.sharedData.claimStatus),
			};
		},
		invoiceData () {
			return {
				numbers: this.sharedData.invoiceNumbers,
				invalid: !!this.sharedData.invoiceNumbers.length && this.sharedData.fraudCheckReasons.some(reason => reason.type === FraudCheckReason.DuplicateInvoices),
			};
		},
		labels () {
			return ClaimFormTypeLabels[this.selectedClaimFormType.id];
		},
		anyBlocklistedClaimVet () {
			return this.selectedVets?.some(v => !v.approved || !this.isVetDirectPay(v));
		},
		preventBlocklistedVet () {
			return this.appSettings.preventBlocklistedVetPayment && this.anyBlocklistedClaimVet;
		},
		payeeCaptureReadonly () {
			return this.isReadOnlyMode || this.preventBlocklistedVet;
		},
		duplicateDataClaimIds () {
			if (this.isDuplicate && !this.isReadOnlyMode && !this.isAllManuallyRejected) {
				return this.isDuplicateInvoice ? this.duplicateInvoiceCheckResult.claimIds : this.duplicateClaimCheckResult.claimIds;
			}
			return this.duplicateClaimIds;
		},
		isDuplicate () {
			return this.isDuplicateInvoice || this.isDuplicateClaim;
		},
		isDuplicateClaim () {
			return (!this.duplicateClaimCheckResult.isValid && !this.ignoreDuplicateClaim)
				|| (this.isReadOnlyMode && this.sharedData.claimStatus === claimNumberStatus.RejectedByDuplicateClaim);
		},
		isDuplicateInvoice () {
			return (!this.duplicateInvoiceCheckResult.isValid && !this.ignoreDuplicateInvoice)
				|| (this.isReadOnlyMode && this.sharedData.claimStatus === claimNumberStatus.RejectedByDuplicateInvoice);
		},
		duplicateClaimRequestData () {
			return {
				dateOfLoss: this.basic.dateOfLoss,
				amount: this.basic.amount,
			};
		},
		isReadOnlyVetMode () {
			return this.isReadOnlyMode || isHospitalSource(this.sharedData.claim.claimSource);
		},
	},
	watch: {
		conditions: {
			deep: true,
			handler () {
				this.predictedClaimAmount = this.conditions.every(cc => !!cc.predictedConditionAmount) ? this.conditions.reduce((acc, next) => acc + next.predictedConditionAmount, 0) : 0;

				this.isAllPreExistingConditionError = this.conditions.every(cc => hasPreEx(cc, this.isReadOnlyMode));
				this.isAllWaitingPeriodError = this.conditions.every(cc => hasWaitingPeriod(cc, this.isReadOnlyMode) && !hasPreEx(cc, this.isReadOnlyMode));
				this.waitingPeriodEndDate = this.conditions.map(cc => cc.waitingPeriodEndDate).filter(Boolean).sort().at(-1) ?? null;
				this.isAllNoActiveCoverPeriodError = this.conditions.every(cc => hasNoActiveCover(cc, this.isReadOnlyMode));
				this.isAllDOLPriorPetDOBError = this.conditions.every(cc => cc.isDOLPriorPetDOBError);
				this.isAllNoProductCoverageError = this.conditions.every(cc => hasNoProductCover(cc, this.isReadOnlyMode));
				this.isAllManuallyRejected = this.conditions.every(cc => hasManualRejection(cc, this.isReadOnlyMode));
				this.isMixedRejection = !(this.isAllNoActiveCoverPeriodError || this.isAllDOLPriorPetDOBError || this.isAllManuallyRejected || this.isAllNoProductCoverageError || this.isAllWaitingPeriodError || this.isAllPreExistingConditionError)
										&& this.conditions.every(cc => hasManualRejection(cc, this.isReadOnlyMode)
													|| hasWaitingPeriod(cc, this.isReadOnlyMode)
													|| hasNoProductCover(cc, this.isReadOnlyMode)
													|| hasNoActiveCover(cc, this.isReadOnlyMode)
													|| hasPreEx(cc, this.isReadOnlyMode));
			},
		},
		isStepDirty: function (newValue) {
			this.isOriginalData = !newValue;
		},
		'basic.dateOfLoss': function (value) {
			this.onDateOfLossChange(value);
		},
		'basic.dateOfDeath': function () {
			this.touchDateFields();
		},
		'basic.treatmentStart': function () {
			this.touchDateFields();
		},
		'basic.treatmentEnd': function () {
			this.touchDateFields();
		},
		'basic.amount': function () {
			this.updateDifference();
		},
		duplicateClaimRequestData: debounce(function () {
			this.validateDuplicateClaim();
		}, 500),
		predictedClaimAmount: function () {
			this.updateDifference();
		},
		isMissingItemsToggled: function (value) {
			this.changeVisibility(StepNameEnum.ClaimMissingItems, value);
		},
		isProcessStepValid: function (newValue) {
			const stepData = this.getStepData(StepNameEnum.ClaimProcess);
			stepData.status = newValue ? statusEnum.SUCCESS : statusEnum.WAIT;
		},
		isAnyFieldPopulated (val) {
			this.changeIsAnyBasicFieldsPopulated(val);
		},
		differenceAmount (val) {
			const displayStatus = toClaimDisplayStatus(this.sharedData.claimStatus);

			if (finalClaimDisplayStatuses.includes(displayStatus)) {
				return;
			}

			if (val > 0) {
				this.showDiffAmount(this.predictedClaimAmount, val);
			} else {
				this.removeActiveClaimAmountToast();
			}
		},
		selectedClaimFormType (value) {
			if (value.id === ClaimFormType.OtherLosses) {
				this.selectedVets = [];
				this.selectedPayees = this.selectedPayees.filter(payee => payee.id !== PayeeType.Vet);
			}
		},
		isOpenDocPanel (isOpen) {
			if (!isOpen && !this.isReadOnlyMode) {
				this.validateDuplicateInvoice();
			}
		},
	},
	async mounted () {
		eventBus.$on('populate-basic', this.populateFromClaimForm);
		eventBus.$on('populate-basic-recognition', this.populateFromRecognition);
		this.changeIsAnyBasicFieldsPopulated(false);
		await this.mountClaimPage();
		await this.mountPage();
		this.validateDuplicateInvoice();
		this.isLoaded = true;
	},
	beforeDestroy () {
		eventBus.$off('populate-basic', this.populateFromClaimForm);
		eventBus.$off('populate-basic-recognition', this.populateFromRecognition);
	},
	methods: {
		...mapActions(['changeIsAnyBasicFieldsPopulated', 'setClaimPayees', 'setClaimRegisteredVets']),
		...mapMutations(['setClaimStepState', 'setClaimFormType']),
		updateDifference () {
			this.differenceAmount = this.predictedClaimAmount > 0 ? this.basic.amount - this.predictedClaimAmount : null;
		},
		isDuplicateCondition (condition) {
			const conditionIndex = this.conditions.findIndex(c => c === condition);
			return condition.selectedAilment && condition.selectedConditionType && condition.selectedBodyPart
						&& this.conditions.some((other, otherIndex) => otherIndex !== conditionIndex && other.selectedAilment && other.selectedConditionType && other.selectedBodyPart
																												&& condition.selectedAilment.id === other.selectedAilment.id
																												&& condition.selectedConditionType.id === other.selectedConditionType.id
																												&& condition.selectedBodyPart.id === other.selectedBodyPart.id);
		},
		async mountPage () {
			this.isProcessStepPassed = this.getStepData(StepNameEnum.ClaimProcess).isPassed;
			this.petId = this.sharedData.petId;
			const claim = this.sharedData.claim;
			this.claimId = claim.claimId;
			this.originalStepData = cloneDeep(claim);
			this.basic = claim.basicData;
			this.conditions = claim.conditions.length ? claim.conditions.map(condition => ({
				id: condition.id,
				selectedAilment: condition.ailment,
				selectedBodyPart: condition.bodyPart,
				selectedConditionType: condition.conditionType,
				parentConditionId: condition.parentConditionId,
				parentConditionAilment: condition.parentConditionAilment,
				parentClaimId: condition.parentClaimId,
				parentClaimDateOfLoss: condition.parentClaimDateOfLoss,
				eligibleConditionValidationResult: null,
				preExConditionValidationResult: null,
				rejectionData: condition.rejectionData,
				ignorePreExistingCondition: condition.wasIgnoredPreExistingCondition,
				ignoreNoProductCoverage: condition.wasIgnoredNoProductCoverage,
				ignoreNoCoverPeriod: condition.wasIgnoredNoCoverPeriod,
				ignoreWaitingPeriod: condition.wasIgnoredWaitingPeriod,
				isNoActiveCoverPeriodError: false,
				isDOLPriorPetDOBError: false,
				isWaitingPeriodError: false,
				waitingPeriodEndDate: condition.waitingPeriodEndDate,
				isNoProductCoverageError: false,
				isParent: condition.isParent,
				state: condition.state,
				eligibleConditionId: condition.eligibleConditionId,
				predictedConditionAmount: null,
			})) : this.conditions;
			this.$emit('update-data', { ...this.sharedData, conditions: this.conditions });
			this.selectedVets = claim.vets;
			this.isMissingItemsToggled = claim.isRequestTriggered;
			const claimPayees = claim.payees.map((id) => ({ id }));
			this.checkClaimPayees(claimPayees);
			this.selectedClaimFormType = { ...ClaimFormTypeOptions.find(x => x.id === claim.formType) };
			this.preAuthorisationCompleted = claim.preAuthorisationCompleted;
			this.ignoreDuplicateClaim = claim.wasIgnoredDuplicateClaim;
			this.ignoreDuplicateInvoice = claim.wasIgnoredDuplicateInvoice;
			this.duplicateClaimIds = claim.duplicateClaimIds;
			if (this.claimData.isContinuation || this.claimData.isParent) {
				this.continuationGroupDateOfLoss = this.originalStepData.basicData.dateOfLoss;
			}
			this.setClaimFormType(this.selectedClaimFormType);
			await this.$nextTick();
			this.$v.$reset();
		},
		populateFromClaimForm (data) {
			this.basic.description = data.conditionData.description;
			this.basic.dateOfLoss = fromStringToDate(data.conditionData.dateOfLoss);
			if (this.claimData.isContinuation || this.claimData.isParent) {
				this.onDateOfLossChange(this.conditionData.dateOfLoss);
			}
			this.basic.dateOfDeath = fromStringToDate(data.conditionData.dateOfDeath);
			this.basic.treatmentStart = fromStringToDate(data.conditionData.treatmentStart);
			this.basic.treatmentEnd = fromStringToDate(data.conditionData.treatmentEnd);
			this.basic.amount = data.conditionData.claimAmount ? Number(data.conditionData.claimAmount) : 0.00;
			this.selectedClaimFormType = { ...ClaimFormTypeOptions.find(x => x.id === data.conditionData.claimFormType) };
			this.onSelectClaimFormType(this.selectedClaimFormType);
			const [condition] = this.conditions;
			condition.selectedAilment = data.conditionData.ailment;
			if (data.conditionData.ailment && data.conditionData.ailment.conditionTypes.filter((item) => item.system).length === 1) {
				condition.selectedConditionType = data.conditionData.ailment.conditionTypes.filter((item) => item.system)[0];
			}
			this.selectedVets = [];
			if (data.vet) {
				this.selectedVets.push({ ...data.vet, isEditable: true });
			}

			if (data.payeeInformation) {
				this.selectedPayees = [];
				if (data.payeeInformation.payPolicyHolder.selected) {
					this.selectedPayees.push({ id: PayeeType.PolicyHolder });
				}
				if (data.payeeInformation.payVeterinaryHospital.selected) {
					this.selectedPayees.push({ id: PayeeType.Vet });
				}
			}
			this.createLinkedClaim(data);
		},
		populateFromRecognition (data) {
			this.basic.treatmentStart = fromStringToDate(data.treatmentStart);
			this.basic.treatmentEnd = fromStringToDate(data.treatmentEnd);
		},
		checkContinuationDates (value) {
			if (this.multicondition || !value || this.isReadOnlyMode) {
				return;
			}

			if (this.claimData.isContinuation || this.claimData.isParent) {
				ClaimService.validateDateOfLoss(this.parentClaimIds.length === 0 ? [this.claimId] : this.parentClaimIds, value, this.claimId)
					.then(result => (this.isContinuationDolValid = result));
			} else {
				this.isContinuationDolValid = true;
			}
		},
		isSafeToContinue () {
			this.$v.$touch();
			return !this.$v.$invalid;
		},
		updateProcessStep () {
			if (!this.isProcessStepValid) {
				const processStepData = this.getStepData(StepNameEnum.ClaimProcess);
				processStepData.isPassed = false;
			}
		},
		onNextProceed () {
			if (this.singlecondition && this.fraudCheck) return this.goNext();
			const leavePage = this.isLastStep && !this.refineRejection;
			return this.saveStep()
				.then(() => {
					if (this.stepProceedAborted) {
						return;
					}
					this.isOriginalData = true;
					this.saveToOriginalStep();
					if (leavePage) {
						this.leavePage();
						return;
					}
					if (this.status === pageStatusEnum.Success || (this.status === pageStatusEnum.Warning && !this.inRejectionStatus)) return this.goNext();
					else this.leavePage();
				})
				.catch(e => this.handleError(e));
		},
		async onNext () {
			this.setClaimPayees(this.selectedPayees.map(({ id }) => id));
			if (this.isReadOnlyMode) {
				if (!invalidPageStatuses.includes(this.status) && (this.originalStepData.isRequestTriggered || this.originalStepData.hasClaimItems || this.originalStepData.hasPayments || this.sharedData.isReassessment)) await this.goNext();
				else this.leavePage();
			} else if (this.isSafeToContinue()) {
				if (this.isAffectOtherSteps) {
					const userChoice = await this.$refs.continueDialog.show();
					if (userChoice === 'yes') {
						return this.onNextProceed();
					}
					if (userChoice === 'no') {
						this.basic = cloneDeep(this.originalStepData.basicData);
						this.conditions = cloneDeep(this.originalStepData.conditions).map(condition => ({
							id: condition.id,
							selectedAilment: condition.ailment,
							selectedBodyPart: condition.bodyPart,
							selectedConditionType: condition.conditionType,
							parentConditionId: condition.parentConditionId,
							parentClaimId: condition.parentClaimId,
							parentClaimDateOfLoss: condition.parentClaimDateOfLoss,
						}));
						this.$emit('update-data', { ...this.sharedData, conditions: this.conditions });
						this.selectedVets = cloneDeep(this.originalStepData.vets);
						this.isMissingItemsToggled = this.originalStepData.isRequestTriggered;
						this.changeVisibility(StepNameEnum.ClaimMissingItems, this.isMissingItemsToggled);
						this.isOriginalData = true;
						this.saveToOriginalStep();
						await this.$nextTick();
						if (this.status === pageStatusEnum.Success) await this.goNext();
					}
				} else if (await this.onValidateDateOfDeathCheck()) {
					return this.onNextProceed();
				}
			}
		},
		async onValidateDateOfDeathCheck () {
			if (this.interactionContext.pet.dateOfDeath) {
				const prevDt = this.interactionContext.pet.dateOfDeath;
				const currDt = this.basic.dateOfDeath;
				if (!areDatesEqual(prevDt, currDt)) {
					const proceed = await this.$refs.dateOfDeathDialog.show() === 'yes';
					if (proceed) {
						this.basic.dateOfDeath = currDt;
						this.interactionContext.pet.dateOfDeath = currDt;
					} else {
						this.basic.dateOfDeath = prevDt;
					}
					return proceed;
				}
			}
			return true;
		},
		async handleError (e) {
			if (isKnownError(e, ErrorCode.ClaimCannotMovedToStatus)) {
				await this.saveStep(true);
				this.isOriginalData = true;
				this.leavePage();
			} else {
				throw e;
			}
		},
		onManualRejectSelect (conditionIndex) {
			if (this.multicondition || this.isSafeToContinue()) {
				this.currentRejectedConditionIndex = conditionIndex || 0;
				const currentRejectedCondition = this.conditions[this.currentRejectedConditionIndex];
				this.rejectionContext = {
					claimId: this.claimId,
					dateOfLoss: this.basic.dateOfLoss,
					treatmentStartDate: this.basic.treatmentStart,
					treatmentEndDate: this.basic.treatmentEnd,
					conditionTypeId: currentRejectedCondition.selectedConditionType ? currentRejectedCondition.selectedConditionType.id : null,
					rejectionData: currentRejectedCondition.rejectionData,
				};
				this.$modal.show('manual-reject');
			}
		},
		onManualRejectRemove (conditionIndex) {
			const condition = this.conditions[conditionIndex || 0];
			condition.rejectionData.isManuallyRejected = false;
			condition.rejectionData.rejectionReasonId = 0;
			condition.rejectionData.rejectionReasonDescription = null;
			condition.rejectionData.comment = null;
			this.stepProceedAborted = false;
		},
		onManualRefineRejectSelect (conditionIndex) {
			this.refineRejection = true;
			this.onManualRejectSelect(conditionIndex);
		},
		onManualRejectCancel () {
			this.refineRejection = false;
			this.currentRejectedConditionIndex = null;
			this.rejectionContext = {};
		},
		onManualReject (value) {
			const condition = this.conditions[this.currentRejectedConditionIndex];
			this.rejectionContext = {};
			condition.rejectionData.isManuallyRejected = true;
			condition.rejectionData.rejectionReasonId = value.reasonId;
			condition.rejectionData.rejectionReasonDescription = value.description;
			condition.rejectionData.comment = value.comment;
			this.cancelOverrideConditionRejection(this.currentRejectedConditionIndex);
			this.currentRejectedConditionIndex = null;

			if (this.singlecondition) {
				if (this.refineRejection) {
					this.onNext();
				} else {
					this.exitClaim();
				}
			}
		},
		onFraudCheckSelect () {
			if (this.isSafeToContinue()) {
				this.$modal.show('fraud-check');
			}
		},
		onFraudCheck (reasons, comment) {
			this.fraudCheck = true;
			this.fraudCheckReasons = reasons;
			this.fraudCheckComment = comment;
			this.exitClaim();
		},
		async overrideConditionRejection (conditionIndex) {
			const condition = this.conditions[conditionIndex || 0];
			if (this.isDuplicateInvoice) {
				this.ignoreDuplicateInvoice = true;
			} else if (this.isDuplicateClaim) {
				this.ignoreDuplicateClaim = true;
			} else if (hasPreEx(condition)) {
				condition.ignorePreExistingCondition = true;
			} else if (hasNoActiveCover(condition)) {
				condition.ignoreNoCoverPeriod = true;
			} else if (hasNoProductCover(condition)) {
				condition.ignoreNoProductCoverage = true;
			} else if (hasWaitingPeriod(condition)) {
				condition.ignoreWaitingPeriod = true;
			}
			await this.$nextTick();
			if (this.singlecondition && !isConditionSystemRejected(condition) && !this.isDuplicate) {
				this.stepProceedAborted = false;
				this.onNext();
			}
		},
		cancelOverrideConditionRejection (conditionIndex) {
			const condition = this.conditions[conditionIndex || 0];
			condition.ignoreWaitingPeriod = false;
			condition.ignorePreExistingCondition = false;
			condition.ignoreNoProductCoverage = false;
			condition.ignoreNoCoverPeriod = false;
			this.ignoreDuplicateClaim = false;
			this.ignoreDuplicateInvoice = false;
		},
		onVetsChange (vets) {
			this.setClaimRegisteredVets(vets);
			this.checkClaimPayees(this.selectedPayees);
			this.areVetsDirty = true;
		},
		onRefineRejection () {
			this.stepProceedAborted = false;
			this.refineRejection = true;
			this.onNext();
		},
		async beforeLeaveHandler () {
			if (this.isReadOnlyMode || this.escalationReasonId) return Promise.resolve();
			if (this.isAllManuallyRejected || this.fraudCheck) {
				const saveStep = await this.saveStep();
				if (this.stepProceedAborted) {
					return Promise.reject(new Error('User canceled selecting reassessment outcome'));
				}
				return saveStep;
			}
			if (this.isOriginalData) return Promise.resolve();
			if (this.isSafeToContinue()) {
				if (this.isAffectOtherSteps) {
					const userChoice = await this.$refs.continueDialog.show();
					if (userChoice === 'cross') return Promise.reject(new Error('user click cross'));
					if (userChoice === 'no') {
						this.isMissingItemsToggled = this.originalStepData.isRequestTriggered;
						this.changeVisibility(StepNameEnum.ClaimMissingItems, this.isMissingItemsToggled);
						return Promise.resolve();
					}
					if (userChoice === 'yes') {
						return this.saveStep().then(() => this.updateProcessStep());
					}
				}
				const saveStep = await this.saveStep();
				if (this.stepProceedAborted) {
					return Promise.reject(new Error('User canceled selecting reassessment outcome'));
				}
				return saveStep;
			}
			return Promise.reject(new Error('Not save to move to another step'));
		},
		showOutcomesModal () {
			return !this.refineRejection && this.inRejectionState && !this.escalationReasonId && this.sharedData.isReassessment;
		},
		showRejectionWarningModal () {
			return !this.refineRejection && this.inRejectionState && !this.escalationReasonId && this.originalStepData.hasClaimItems;
		},
		async saveStep (isDraft = false) {
			this.stepProceedAborted = false;
			if (!isDraft) {
				if (!this.isAllManuallyRejected) {
					// VIS-30822: when the user is editing a claim the system will retry the validations for duplicate check (Invoice and Claim)
					const isValidDuplicateClaimBeforeCheck = this.duplicateClaimCheckResult.isValid;
					const isValidDuplicateInvoiceBeforeCheck = this.duplicateInvoiceCheckResult.isValid;

					await Promise.all([this.validateDuplicateClaim(), this.validateDuplicateInvoice()]);
					if (isValidDuplicateClaimBeforeCheck !== this.duplicateClaimCheckResult.isValid
						|| isValidDuplicateInvoiceBeforeCheck !== this.duplicateInvoiceCheckResult.isValid) {
						this.stepProceedAborted = true;
						return;
					}
				}

				if (this.inRejectionState) {
					this.isMissingItemsToggled = false;
				}

				if (this.showRejectionWarningModal()) { // VIS-30583
					const userChoice = await this.$refs.confirmRejection.show();
					if (userChoice === 'no' || userChoice === 'cross') {
						this.stepProceedAborted = true;
						return;
					}
				}
				if (this.showOutcomesModal()) {
					const userChoice = await this.$refs.saveReassessmentOutcomeModal.show(outcomeState.Rejected);
					if (userChoice === 'no' || userChoice === 'cross') {
						this.stepProceedAborted = true;
						return;
					}
				}
			}

			const vetIds = this.selectedVets.map((vet) => vet.id);
			const conditions = this.conditions.map(c => ({
				id: c.id,
				ailmentId: c.selectedAilment ? c.selectedAilment.id : null,
				conditionTypeId: c.selectedConditionType ? c.selectedConditionType.id : null,
				bodyPartId: c.selectedBodyPart ? c.selectedBodyPart.id : null,
				parentConditionId: c.parentConditionId,
				ignorePreExistingCondition: c.ignorePreExistingCondition,
				ignoreWaitingPeriod: c.ignoreWaitingPeriod,
				ignoreNoCoverPeriod: c.ignoreNoCoverPeriod,
				ignoreNoProductCoverage: c.ignoreNoProductCoverage,
				rejectionData: c.rejectionData,
			}));

			const data = new ClaimInput(
				this.basic.description,
				this.basic.dateOfLoss,
				this.basic.dateOfDeath,
				this.basic.treatmentStart,
				this.basic.treatmentEnd,
				this.basic.amount,
				conditions,
				vetIds,
				this.isMissingItemsToggled,
				isDraft,
				this.fraudCheck,
				this.fraudCheckReasons,
				this.fraudCheckComment,
				this.selectedClaimFormType.id,
				this.selectedPayees.map(payee => payee.id),
				this.selectedReassessmentOutcome?.id,
				this.refineRejection,
				this.ignoreDuplicateClaim,
				this.ignoreDuplicateInvoice,
			);
			return ClaimService.proceedClaim(this.claimId, data)
				.then((claimStatus) => this.updateClaimStatus(claimStatus));
		},
		async saveDraft () {
			if (this.isReadOnlyMode
				|| this.isAllManuallyRejected
				|| this.fraudCheck
				|| this.escalationReasonId) {
				return Promise.resolve();
			}

			if (this.isOriginalData) {
				if (this.sharedData.claimStatus === claimNumberStatus.New) {
					const files = await FileService.getClaimFiles(this.claimId);
					if (files.length > 0) {
						return this.saveStep(true);
					}
					return ClaimService.cancelClaim(this.claimId);
				}
				return Promise.resolve();
			} else {
				const userAction = await this.$refs.saveChangesDialog.show();
				if (userAction === 'no') {
					if (this.sharedData.claimStatus === claimNumberStatus.New) {
						const files = await FileService.getClaimFiles(this.claimId);
						if (files.length > 0) {
							return this.saveStep(true);
						}
						return ClaimService.cancelClaim(this.claimId);
					}
					return Promise.resolve();
				}
				if (userAction === 'yes') {
					this.isDraft = true;
					if (!this.$v.$invalid) {
						return this.saveStep(true);
					}
					await this.$refs.saveChangesAlert.show();
					this.isDraft = false;
					return Promise.reject(new Error('invalid data'));
				}
				return Promise.reject(new Error('user click cross'));
			}
		},
		saveToOriginalStep () {
			this.originalStepData.basicData = cloneDeep(this.basic);
			this.originalStepData.conditions = this.conditions.map(c => ({
				ailment: cloneDeep(c.selectedAilment),
				conditionType: cloneDeep(c.selectedConditionType),
				bodyPart: cloneDeep(c.selectedBodyPart),
				parentConditionId: c.parentConditionId,
				parentClaimId: c.parentClaimId,
				parentClaimDateOfLoss: c.parentClaimDateOfLoss,
			}));
			this.originalStepData.vets = cloneDeep(this.selectedVets);
			this.originalStepData.isRequestTriggered = cloneDeep(this.isMissingItemsToggled);
			this.originalStepData.selectedPayees = cloneDeep(this.selectedPayees);
		},
		onCancel () {
			this.leavePage();
		},
		onUnbindClaim (conditionIndex) {
			this.conditions[conditionIndex || 0].parentConditionId = null;
			this.conditions[conditionIndex || 0].parentConditionAilment = null;
			this.conditions[conditionIndex || 0].parentClaimId = null;
			this.conditions[conditionIndex || 0].parentClaimDateOfLoss = null;
			this.conditions = [...this.conditions];
			this.$emit('update-data', { ...this.sharedData, conditions: this.conditions });

			this.continuationGroupDateOfLoss = null;
			this.isContinuationDolValid = true;
		},
		onBindParentCondition (claimConditionIndex, parentClaimCondition) {
			if (!parentClaimCondition) {
				this.selectedConditionIndex = null;
				this.onUnbindClaim(claimConditionIndex);
			} else {
				this.selectedConditionIndex = claimConditionIndex;
				this.onBindClaim(parentClaimCondition);
			}
		},
		onBindClaim (parentClaimCondition) {
			this.$modal.hide('continuation-claim');
			const conditionIndex = this.selectedConditionIndex || 0;
			this.conditions[conditionIndex].parentConditionId = parentClaimCondition.conditionId;
			this.conditions[conditionIndex].parentConditionAilment = parentClaimCondition.ailment;
			this.conditions[conditionIndex].parentClaimId = parentClaimCondition.claimId;
			this.conditions[conditionIndex].parentClaimDateOfLoss = parentClaimCondition.dateOfLoss;

			if (!this.conditions[conditionIndex].selectedAilment && !this.conditions[conditionIndex].selectedBodyPart) {
				this.conditions[conditionIndex].selectedAilment = parentClaimCondition.ailment;
				this.conditions[conditionIndex].selectedBodyPart = parentClaimCondition.bodyPart;
				this.conditions[conditionIndex].selectedConditionType = parentClaimCondition.conditionType;

				this.$refs.claimConditions.onAilmentChanged(conditionIndex, this.conditions[conditionIndex].selectedAilment);
			}

			this.conditions = [...this.conditions];
			this.$emit('update-data', { ...this.sharedData, conditions: this.conditions });
			this.selectedConditionIndex = null;
			this.continuationGroupDateOfLoss = fromStringToDate(parentClaimCondition.dateOfLoss);
			if (this.singlecondition && !areDatesEqual(this.continuationGroupDateOfLoss, this.basic.dateOfLoss)) {
				this.onDateOfLossChange(this.basic.dateOfLoss, true);
			}
		},
		onContinuationClaim (conditionIndex) {
			this.selectedConditionIndex = conditionIndex;
			this.$modal.show('continuation-claim');
		},
		onMissingItemsToggle (isShow) {
			this.changeVisibility(StepNameEnum.ClaimMissingItems, isShow);
		},
		async onDateOfLossChange (value, isClaimsGrouping) {
			if (this.isReadOnlyMode) {
				return;
			}

			this.touchDateFields();
			// if current claim is parent or continuation claim
			if ((this.singlecondition && (this.claimData.isContinuation || this.claimData.isParent)) || (this.multicondition && this.claimData.isParent)) {
				// and user try to change DOL then for cases where we group claim with parent claim and continuation DOL is less or equal to current DOL we will update current DOL to continuation DOL
				if (this.singlecondition
				&& this.continuationGroupDateOfLoss
				&& ((isClaimsGrouping && (!this.basic.dateOfLoss || this.continuationGroupDateOfLoss < this.basic.dateOfLoss))
				|| areDatesEqual(this.continuationGroupDateOfLoss, this.basic.dateOfLoss))) {
					this.basic.dateOfLoss = this.continuationGroupDateOfLoss;
				// for other cases we will ask user which DOL should we use: current DOL or original continuation DOL
				} else if (this.basic.dateOfLoss) {
					const userAction = await this.$refs.changeDol.show();
					if (userAction === 'yes') {
						this.basic.dateOfLoss = value;
					} else {
						this.basic.dateOfLoss = this.continuationGroupDateOfLoss || this.originalStepData.basicData.dateOfLoss;
					}
				} else {
					this.basic.dateOfLoss = value;
				}
			}

			this.checkContinuationDates(this.basic.dateOfLoss);
		},
		async createLinkedClaim (data) {
			if (!data.secondConditionData) {
				return Promise.resolve();
			}
			const parentClaimId = this.claimId;
			if (data.secondConditionData.ailment) {
				data.secondConditionData.ailmentId = data.secondConditionData.ailment.id;
				const conditionTypes = data.secondConditionData.ailment.conditionTypes.filter((item) => item.system);
				if (conditionTypes.length === 1) {
					data.secondConditionData.conditionTypeId = conditionTypes[0].id;
				}
			}
			if (data.vet) {
				data.secondConditionData.vetIds = [data.vet.id];
			}
			data.secondConditionData.dateOfLoss = fromStringToDate(data.secondConditionData.dateOfLoss);
			data.secondConditionData.dateOfDeath = fromStringToDate(data.secondConditionData.dateOfDeath);
			data.secondConditionData.treatmentStart = fromStringToDate(data.secondConditionData.treatmentStart);
			data.secondConditionData.treatmentEnd = fromStringToDate(data.secondConditionData.treatmentEnd);

			if (data.payeeInformation) {
				const payeeTypes = [];
				if (data.payeeInformation.payPolicyHolder.selected) {
					payeeTypes.push(PayeeType.PolicyHolder);
				}
				if (data.payeeInformation.payVeterinaryHospital.selected) {
					payeeTypes.push(PayeeType.Vet);
				}

				data.secondConditionData.payeeTypes = payeeTypes;
			}

			await ClaimService.createLinkedClaim(parentClaimId, data.fileId, data.secondConditionData);
		},
		showDiffAmount (predicted, difference) {
			this.removeActiveClaimAmountToast();

			this.activeClaimAmountToast = this.$toasted.show(`The Suggested Claim Amount is&nbsp;<strong>${currency(predicted, this.locale)}</strong>. It is&nbsp;<strong>${currency(difference, this.locale)}&nbsp;less</strong>&nbsp;than entered Claim Amount.`,
				toastOptions);
		},
		removeActiveClaimAmountToast () {
			if (this.activeClaimAmountToast) {
				this.activeClaimAmountToast.goAway(0);
			}
		},
		touchDateFields () {
			if (this.basic.dateOfLoss) this.$v.basic.dateOfLoss.$touch();
			if (this.basic.dateOfDeath) this.$v.basic.dateOfDeath.$touch();
			if (this.basic.treatmentStart) this.$v.basic.treatmentStart.$touch();
			if (this.basic.treatmentEnd) this.$v.basic.treatmentEnd.$touch();
		},
		shortDateOrNoDate (dt) {
			return shortDate(dt) || 'No Date';
		},
		async onFraudChekActionFinish (leaveClaimPage) {
			await this.mountClaimPage();
			if (leaveClaimPage) {
				this.$router.back();
			}
		},
		onInputPayees (value) {
			this.selectedPayees = value;
			this.$v.selectedPayees.$touch();
		},
		updateClaimStepState () {
			const claim = {
				basic: this.basic,
				conditions: this.conditions,
				vets: this.selectedVets,
				payees: this.selectedPayees,
			};
			this.setClaimStepState(claim);
		},
		async onSelectClaimFormType (claimFormType) {
			if (claimFormType.id === ClaimFormType.OtherLosses
				|| this.selectedClaimFormType.id === ClaimFormType.OtherLosses) {
				this.conditions.forEach(cond => {
					cond.selectedBodyPart = null;
					cond.selectedConditionType = null;
					cond.selectedAilment = null;
				});
			}
			if (claimFormType.id === ClaimFormType.OtherLosses) {
				this.selectedPayees = [{ id: PayeeType.PolicyHolder }];
			}
			if (claimFormType.id === ClaimFormType.Standard && this.sharedData.claimStatus === claimNumberStatus.PreAuthorisationCompleted) {
				const userChoice = await this.$refs.confirmFormType.show();
				if (userChoice === 'yes') {
					this.selectedClaimFormType = claimFormType;
					this.preAuthorisationCompleted = true;
				}
			} else {
				this.selectedClaimFormType = claimFormType;
			}
			this.setClaimFormType(claimFormType);
		},
		async onClaimEscalateSelect () {
			this.escalationReasonId = await this.onClaimEscalate(this.isStepDirty);

			if (this.escalationReasonId) {
				try {
					await this.exitClaim();
					await ClaimService.escalate(this.claimId, this.escalationReasonId);
				} catch {
				}
				this.escalationReasonId = null;
			}
		},
		async onRemoveEscalation () {
			await this.exitClaim();
			await ClaimService.removeEscalation(this.claimId);
		},
		checkClaimPayees (claimPayees) {
			const preventVetPayment = this.appSettings.preventBlocklistedVetPayment && this.anyBlocklistedClaimVet;
			const disableSplitPayments = this.appSettings.disableSplitPayments && claimPayees.length > 1;
			if (!this.isReadOnlyMode && (preventVetPayment || disableSplitPayments)) {
				this.selectedPayees = [{ id: PayeeType.PolicyHolder }];
			} else {
				this.selectedPayees = claimPayees;
			}
		},
		isVetDirectPay (vet) {
			return VetService.isDirectPay(vet);
		},
		validateDuplicateClaim () {
			if (this.isReadOnlyMode || !this.appSettings.rejectDuplicate
			|| !this.basic.dateOfLoss || !this.basic.amount) return;

			return ClaimService.validateDuplicateClaim(this.sharedData.claimId, this.basic.dateOfLoss, this.basic.amount)
				.then(res => {
					this.duplicateClaimCheckResult = res;
					if (this.duplicateClaimCheckResult.isValid) {
						this.ignoreDuplicateClaim = false;
					}
				});
		},
		validateDuplicateInvoice () {
			if (this.isReadOnlyMode || !this.appSettings.rejectDuplicate || !this.invoiceData.numbers.length) return;

			return ClaimService.validateDuplicateInvoice(this.sharedData.claimId)
				.then(res => {
					this.duplicateInvoiceCheckResult = res;
					if (this.duplicateInvoiceCheckResult.isValid) {
						this.ignoreDuplicateInvoice = false;
					}
				});
		},
	},
};
</script>

<style lang="scss">
.not-allowed-save-draft-tooltip {
  top: -8px !important;
}
</style>

<style lang="scss" scoped>
.claim-form {
  ::v-deep .form-category-container:last-child .status-line {
    display: block !important;
  }

  .conditions_container {
    margin-top: -19px;
    margin-bottom: -19px;
  }
}

.error .text-danger {
  font-size: 12px;
}

.predicted-amount {
  color: $active-color;
}

.mb-85 {
  margin-bottom: 85px;
}

.pet-alert-logo {
  position: relative;
  left: 40%;
}

::v-deep .claim-link {
  color: white;

  &:hover {
    color: $primary-d-20;
  }
}

.btn-continuation {
  height: 30px;
  width: 200px;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 25px;
  margin-bottom: 12px;
}

.mb-65 {
  margin-bottom: 65px;
}

.claim-form-type-selector {
  width: 200px
}
</style>
