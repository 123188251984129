<template>
	<div>
		<aq-highlight-border-with-icon
			:highlight-color="newParentWarningMessage && 'warning'"
			:icon="newParentWarningMessage && 'fa-exclamation-triangle'"
			class="condition"
		>
			<aq-select
				data-qa="aqConditionParents_input_ailment"
				:options="parentClaims"
				:is-sorted="false"
				label="Parent"
				option-label="description"
				class="w-100"
				:value="selectedOption"
				@input="$emit('input', $event)"
				no-result-message="No Parent found"
				:disabled="disabled"
			>
				<template #default="props">
					<div
						v-if="props.option.claimId"
						class="py-4 d-flex flex-row gap-m"
					>
						<div>
							<div class="select-option-header text-truncate">
								DOL: {{ props.option.dateOfLoss | shortDate }}
							</div>
							<div class="select-option-info text-truncate">
								Id: {{ props.option.claimId }}
							</div>
						</div>
						<div class="w-40">
							<div class="select-option-header text-truncate">
								{{ props.option.ailment.firstCause }}
							</div>
							<div class="select-option-info text-truncate">
								Body part: {{ props.option.bodyPart.description }}
							</div>
						</div>
						<div class="d-flex align-items-center gap-s">
							<div class="circle-badge">
								{{ props.option.continuationConditionsCount + 1 }}
							</div>
							<i
								v-if="props.option.preExistingConditionId"
								class="circle-badge solid fas fa-band-aid prex"
							/>
							<i
								v-if="props.option.eligibleConditionId"
								class="circle-badge solid fas fa-band-aid eligible"
							/>
						</div>
					</div>
					<div v-else>
						{{ props.option.description }}
					</div>
				</template>
			</aq-select>
			<template slot="tooltipContent">
				<div>{{ newParentWarningMessage }}</div>
			</template>
		</aq-highlight-border-with-icon>
	</div>
</template>

<script>

import { ClaimFormType } from '@commonServices/models/ClaimFormType';
import { mapState } from 'vuex';
import AqHighlightBorderWithIcon from '@commonWidgets/AqHighlightBorderWithIcon';

export default {
	name: 'AqConditionParents',
	components: { AqHighlightBorderWithIcon },
	props: {
		parentClaimConditionId: {
			type: Number,
			required: false,
			default: null,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		claimId: {
			type: [String, Number],
			required: true,
		},
		parentPetClaims: {
			type: Array,
			required: false,
			default: () => [],
		},
		markedParentConditionIds: {
			type: Array,
			required: false,
			default: () => [],
		},
		multiCondition: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		...mapState(['appSettings', 'claimFormType']),
		noParentOption () {
			return {
				claimId: null,
				description: 'No Parent',
				canBeParent: true,
			};
		},
		selectedOption () {
			return this.parentPetClaims.find(x => x.conditionId === this.parentClaimConditionId) || this.noParentOption;
		},
		parentClaims () {
			const parentClaims = this.parentPetClaims.filter(item =>
				this.claimId !== item.claimId
				&& !(this.multiCondition && !item.canBeParent && this.markedParentConditionIds.includes(item.conditionId))
				&& (item.formType === this.claimFormType.id || (this.appSettings.extendedContinuationParenting && item.formType !== ClaimFormType.OtherLosses && this.claimFormType.id !== ClaimFormType.OtherLosses)))
				.sort((first, second) => {
					return first.dateOfLoss > second.dateOfLoss;
				});

			// Add new "no parent" option to top of the list
			parentClaims.unshift(this.noParentOption);

			return parentClaims;
		},
		newParentWarningMessage () {
			return !this.selectedOption.canBeParent ? 'Current condition will become the parent condition' : null;
		},
	},
};
</script>

<style lang="scss" scoped>
.circle-badge {
  font-size: 12px;
  display: flex;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;

  &.solid {
    background: #fff;
  }
}

.w-40 {
  width: 40%;
}

.prex {
  color: $primary;
}

.eligible {
  color: var(--infoColor);
}

.select-option-header {
  font-size: 12px;
}

.select-option-info {
  font-size: 10px;
}

</style>
