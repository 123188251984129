<template>
	<div class="d-flex flex-column active-layout-height">
		<div class="section-header d-flex create-claim-header">
			<div>
				<div class="section-header__title">
					Create Claim
				</div>
				<p>You are now creating a new linked claim for claim id: {{ claimId | claimIdFormat }}</p>
			</div>
			<div
				@click="$emit('close')"
				v-if="!isReadOnlyMode"
				class="close text-white ml-auto mr-24"
			>
				<i class="fas fa-times fa-lg" />
			</div>
		</div>
		<div class="section-content items-list">
			<div class="flex-grow-1 d-flex flex-column ml-20 mr-20 ">
				<div class="section-header section-header-min-height">
					<div class="section-header__title">
						Basic Details
					</div>
				</div>
				<div class="row no-gutters mt-20 claim-basic">
					<ClaimBasic
						:locale="locale"
						:basic="basic"
						:validator="$v.basic"
						:is-read-only-mode="isReadOnlyMode"
						:labels="labels"
					/>
				</div>
				<div class="section-header section-header-min-height mt-40">
					<div class="section-header__title">
						{{ labels.diagnosis }}
					</div>
				</div>
				<div class="row no-gutters mt-20">
					<div class="col-6">
						<aq-loss-types
							v-if="isOtherLoss"
							:is-valid="!$v.selectedAilment.$error"
							v-model="selectedAilment"
							:disabled="isReadOnlyMode"
						/>
						<aq-ailments
							v-else
							data-qa="addClaimLayout_input_ailment"
							v-model="selectedAilment"
							@input="updateConditionType"
							:is-valid="!$v.selectedAilment.$error"
						/>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="errorMessages"
							:validator="$v"
							property="selectedAilment"
						/>
					</div>
					<div
						v-if="!isOtherLoss"
						class="col-3 pl-10"
					>
						<aq-body-parts
							data-qa="addClaimLayout_input_bodyPart"
							v-model="selectedBodyPart"
							@input="$v.selectedBodyPart.$touch"
							:is-valid="!$v.selectedBodyPart.$error"
						/>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="errorMessages"
							:validator="$v"
							property="selectedBodyPart"
						/>
					</div>
					<div class="col-3 pl-10">
						<div v-if="selectedAilment">
							<div v-if="getConditionTypes(selectedAilment).length > 1">
								<aq-select
									data-qa="addClaimCondition_input_conditionType"
									:options="getConditionTypes(selectedAilment)"
									label="Condition Type"
									option-label="description"
									:is-valid="!$v.selectedConditionType.$error"
									class="w-100"
									v-model="selectedConditionType"
									track-by="id"
									@input="$v.selectedConditionType.$touch"
									no-result-message="No Condition Types found"
								/>
								<aq-form-input-error
									class="error mt-4"
									:error-messages="errorMessages"
									:validator="$v"
									property="selectedConditionType"
								/>
							</div>
							<div v-if="getConditionTypes(selectedAilment).length === 1 && selectedConditionType">
								<div class="label text-primary">
									Condition Type
								</div>
								<div class="mt-10">
									{{ selectedConditionType.description }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="section-header section-header-min-height mt-40"
					v-if="!isOtherLoss"
				>
					<div class="section-header__title">
						Vet Details
					</div>
				</div>
				<div
					v-if="!isOtherLoss"
					class="mt-20 vets-container"
				>
					<claim-vets
						:selected-vets="selectedVets"
						:registered-vets="registeredVets"
						:is-read-only-mode="isReadOnlyMode"
						:locale="locale"
						:validator="$v"
					/>
				</div>
			</div>
		</div>

		<div
			class="section-footer"
		>
			<div class="container-fluid d-flex justify-content-end">
				<button
					class="btn btn-secondary btn-radius--less mr-10"
					:disabled="isReadOnlyMode"
					@click="$emit('close')"
				>
					Cancel
				</button>
				<button
					class="btn btn-primary btn-radius--less"
					:disabled="isReadOnlyMode || $v.$invalid"
					@click="onCreateClaim"
				>
					Proceed
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { and, maxLength, required, maxValue } from 'vuelidate/lib/validators';
import { spaces, moreThenOrEqual, lessThenOrEqual, moreThen, maxAmount } from '@commonServices/utils/validators';
import { addDays } from '@commonServices/utils/dateUtils';
import { ValidationMessages } from '@commonServices/utils/constants';
import { mapState } from 'vuex';
import ClaimVets from '@commonView/ClaimPage/ClaimEnter/ClaimVets';
import ClaimBasic from '@commonView/ClaimPage/ClaimEnter/ClaimBasic';
import { ClaimFormType, ClaimFormTypeLabels } from '@commonServices/models/ClaimFormType';
import AqLossTypes from '@commonWidgets/AqLossTypes';

const TODAY = new Date();
const FORTNIGHT = 14;

export default {
	name: 'AddClaimLayout',
	components: {
		ClaimVets,
		ClaimBasic,
		AqLossTypes,
	},
	props: {
		claimId: {
			type: Number,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
	},
	data () {
		return {
			basic: {
				description: null,
				dateOfLoss: null,
				dateOfDeath: null,
				treatmentStart: null,
				treatmentEnd: null,
				amount: null,
			},
			selectedAilment: null,
			selectedBodyPart: null,
			selectedConditionType: null,
			selectedVets: [],
		};
	},
	validations () {
		const requireIfNotOtherLossFormType = this.isOtherLoss ? () => true : required;

		return {
			basic: {
				description: {
					required: and(required, spaces),
					maxLength: maxLength(2000),
				},
				dateOfLoss: {
					required,
					maxValue: maxValue(TODAY),
					minValue: moreThenOrEqual(this.minDate),
				},
				dateOfDeath: {
					maxValue: maxValue(TODAY),
					minValue: moreThenOrEqual(this.basic.dateOfLoss),
				},
				treatmentStart: {
					required,
					maxValue: maxValue(TODAY),
					minValue: moreThenOrEqual(this.basic.dateOfLoss),
					deathValue: lessThenOrEqual(this.basic.dateOfDeath ? addDays(this.basic.dateOfDeath, FORTNIGHT) : null),
				},
				treatmentEnd: {
					required,
					maxValue: maxValue(TODAY),
					treatmentStart: moreThenOrEqual(this.basic.dateOfLoss),
					minValue: moreThenOrEqual(this.basic.treatmentStart),
					deathValue: lessThenOrEqual(this.basic.dateOfDeath ? addDays(this.basic.dateOfDeath, FORTNIGHT) : null),
				},
				amount: {
					required,
					minValue: moreThen(0),
					maxAmount: maxAmount(16),
				},
			},
			selectedAilment: {
				required,
			},
			selectedBodyPart: {
				required: requireIfNotOtherLossFormType,
			},
			selectedConditionType: {
				required,
			},
			selectedVets: {
				required: true,
			},
		};
	},
	computed: {
		...mapState(['interactionContext', 'claimFormType']),
		errorMessages () {
			return {
				selectedAilment: {
					required: ValidationMessages.required,
				},
				selectedBodyPart: {
					required: ValidationMessages.required,
				},
				selectedConditionType: {
					required: ValidationMessages.required,
				},
			};
		},
		registeredVets () {
			return this.interactionContext.pet?.registeredVets ?? [];
		},
		isOtherLoss () {
			return this.claimFormType.id === ClaimFormType.OtherLosses;
		},
		labels () {
			return ClaimFormTypeLabels[this.claimFormType.id];
		},
	},
	methods: {
		async onCreateClaim () {
			if (this.$v.$invalid) {
				return;
			}

			const data = {
				description: this.basic.description,
				dateOfLoss: this.basic.dateOfLoss,
				dateOfDeath: this.basic.dateOfDeath,
				treatmentStart: this.basic.treatmentStart,
				treatmentEnd: this.basic.treatmentEnd,
				claimAmount: this.basic.amount,
				ailmentId: this.selectedAilment.id,
				bodyPartId: this.selectedBodyPart?.id,
				conditionTypeId: this.selectedConditionType.id,
				vetIds: this.selectedVets.map((vet) => vet.id),
				claimFormType: this.claimFormType.id,
			};

			this.$emit('create', data);
		},
		updateConditionType () {
			this.$v.selectedAilment.$touch();
			this.selectedConditionType = null;
			if (this.selectedAilment) {
				const conditionTypes = this.getConditionTypes(this.selectedAilment);
				if (conditionTypes.length === 1) {
					this.selectedConditionType = conditionTypes[0];
				}
			}
		},
		getConditionTypes (ailment) {
			return ailment.conditionTypes.filter((item) => item.system);
		},
	},
};
</script>

<style lang="scss" scoped>
  .active-layout-height {
    height: calc(100% - 67px);
  }

  .items-list {
    flex: 1 1 auto;
    overflow-y: auto;
    height: 0;
  }

  .claim-basic {
    ::v-deep .date-input {
      margin-right: 10px;
    }

    ::v-deep .control-container {
      padding-right: 10px;
      padding-left: 10px;

      .label {
        left: 10px;
      }
    }
  }

  .vets-container {
    .container-fluid {
      padding: 0 !important;
    }
  }

  .create-claim-header {
    background-color: $primary;
  }

  .section-header-min-height {
    min-height: 0;
  }

  .close {
    cursor: pointer;
  }

  ::v-deep .vdp-datepicker {
    .vdp-datepicker__calendar {
      width: 250px !important;
    }

    .vdp-datepicker__clear-button {
      right: 20px !important;
    }
  }

</style>
