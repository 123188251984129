import { WorkQueueType } from '@commonServices/models/WorkQueueType';

const claimStatusEnum = Object.freeze({
	NewClaim: 'NewClaim',
	DraftClaim: 'DraftClaim',
	RejectedWaitingPeriod: 'RejectedWaitingPeriod',
	RejectedSectionLimit: 'RejectedSectionLimit',
	RejectedByConditionLimit: 'RejectedByConditionLimit',
	RejectedByMultipleConditions: 'RejectedByMultipleConditions',
	ClosedPaid: 'ClosedPaid',
	Settled: 'Settled',
	RejectionPending: 'RejectionPending',
	FraudCheck: 'FraudCheck',
	MissingInfo: 'MissingInfo',
	RejectedManually: 'RejectedManually',
	FraudCheckOk: 'FraudCheckOk',
	FraudCheckFailed: 'FraudCheckFailed',
	ReassessmentPending: 'ReassessmentPending',
	RejectedByPreExistingCondition: 'RejectedByPreExistingCondition',
	RejectedByRelatedCondition: 'RejectedByRelatedCondition',
	RejectedByNoActiveCoverPeriod: 'RejectedByNoActiveCoverPeriod',
	Rejected: 'Rejected',
	PreAuthorisationCompleted: 'PreAuthorisationCompleted',
	RejectedByNoProductCoverage: 'RejectedByNoProductCoverage',
	RejectedMultiple: 'RejectedMultiple',
	RejectedByDuplicateClaim: 'RejectedByDuplicateClaim',
	RejectedByDuplicateInvoice: 'RejectedByDuplicateInvoice',
});

const claimNumberStatus = Object.freeze({
	New: 0,
	Draft: 1,
	RejectedWaitingPeriod: 2,
	RejectedSectionLimit: 3,
	RejectedByConditionLimit: 4,
	RejectedByMultipleConditions: 5,
	ClosedPaid: 6,
	Settled: 7,
	RejectionPending: 8,
	FraudCheck: 9,
	MissingInfo: 10,
	RejectedManually: 11,
	FraudCheckOk: 12,
	FraudCheckFailed: 13,
	ReassessmentPending: 14,
	RejectedByPreExistingCondition: 15,
	RejectedByNoActiveCoverPeriod: 16,
	RejectedByRelatedCondition: 17,
	PreAuthorisationCompleted: 18,
	RejectedByNoProductCoverage: 19,
	RejectedMultiple: 20,
	RejectedByDuplicateClaim: 21,
	RejectedByDuplicateInvoice: 22,
});

const claimStatusDisplayMap = Object.freeze({
	[claimStatusEnum.NewClaim]: 'New',
	[claimStatusEnum.DraftClaim]: 'Draft',
	[claimStatusEnum.RejectedWaitingPeriod]: 'Rejected',
	[claimStatusEnum.RejectedSectionLimit]: 'Rejected',
	[claimStatusEnum.RejectedByConditionLimit]: 'Rejected',
	[claimStatusEnum.RejectedByMultipleConditions]: 'Rejected',
	[claimStatusEnum.ClosedPaid]: 'Closed-Paid',
	[claimStatusEnum.Settled]: 'Settled',
	[claimStatusEnum.RejectionPending]: 'Rejection Pending',
	[claimStatusEnum.FraudCheck]: 'Fraud Check',
	[claimStatusEnum.MissingInfo]: 'Missing Info',
	[claimStatusEnum.RejectedManually]: 'Rejected Manually',
	[claimStatusEnum.FraudCheckOk]: 'Fraud Check Ok',
	[claimStatusEnum.FraudCheckFailed]: 'Fraud Check Failed',
	[claimStatusEnum.ReassessmentPending]: 'Reassessment Pending',
	[claimStatusEnum.RejectedByPreExistingCondition]: 'Rejected',
	[claimStatusEnum.RejectedByNoActiveCoverPeriod]: 'Rejected',
	[claimStatusEnum.RejectedByRelatedCondition]: 'Rejected',
	[claimStatusEnum.PreAuthorisationCompleted]: 'Pre-Authorisation Completed',
	[claimStatusEnum.RejectedByNoProductCoverage]: 'Rejected',
	[claimStatusEnum.RejectedMultiple]: 'Rejected',
	[claimStatusEnum.RejectedByDuplicateClaim]: 'Rejected',
	[claimStatusEnum.RejectedByDuplicateInvoice]: 'Rejected',
});

const claimNumberStatusDisplayMap = Object.freeze({
	[claimNumberStatus.New]: claimStatusDisplayMap[claimStatusEnum.NewClaim],
	[claimNumberStatus.Draft]: claimStatusDisplayMap[claimStatusEnum.DraftClaim],
	[claimNumberStatus.RejectedWaitingPeriod]: claimStatusDisplayMap[claimStatusEnum.RejectedWaitingPeriod],
	[claimNumberStatus.RejectedSectionLimit]: claimStatusDisplayMap[claimStatusEnum.RejectedSectionLimit],
	[claimNumberStatus.RejectedByConditionLimit]: claimStatusDisplayMap[claimStatusEnum.RejectedByConditionLimit],
	[claimNumberStatus.RejectedByMultipleConditions]: claimStatusDisplayMap[claimStatusEnum.RejectedByMultipleConditions],
	[claimNumberStatus.ClosedPaid]: claimStatusDisplayMap[claimStatusEnum.ClosedPaid],
	[claimNumberStatus.Settled]: claimStatusDisplayMap[claimStatusEnum.Settled],
	[claimNumberStatus.RejectionPending]: claimStatusDisplayMap[claimStatusEnum.RejectionPending],
	[claimNumberStatus.FraudCheck]: claimStatusDisplayMap[claimStatusEnum.FraudCheck],
	[claimNumberStatus.MissingInfo]: claimStatusDisplayMap[claimStatusEnum.MissingInfo],
	[claimNumberStatus.RejectedManually]: claimStatusDisplayMap[claimStatusEnum.RejectedManually],
	[claimNumberStatus.FraudCheckOk]: claimStatusDisplayMap[claimStatusEnum.FraudCheckOk],
	[claimNumberStatus.FraudCheckFailed]: claimStatusDisplayMap[claimStatusEnum.FraudCheckFailed],
	[claimNumberStatus.ReassessmentPending]: claimStatusDisplayMap[claimStatusEnum.ReassessmentPending],
	[claimNumberStatus.RejectedByPreExistingCondition]: claimStatusDisplayMap[claimStatusEnum.RejectedByPreExistingCondition],
	[claimNumberStatus.RejectedByNoActiveCoverPeriod]: claimStatusDisplayMap[claimStatusEnum.RejectedByNoActiveCoverPeriod],
	[claimNumberStatus.RejectedByRelatedCondition]: claimStatusDisplayMap[claimStatusEnum.RejectedByRelatedCondition],
	[claimNumberStatus.PreAuthorisationCompleted]: claimStatusDisplayMap[claimStatusEnum.PreAuthorisationCompleted],
	[claimNumberStatus.RejectedByNoProductCoverage]: claimStatusDisplayMap[claimStatusEnum.RejectedByNoProductCoverage],
	[claimNumberStatus.RejectedMultiple]: claimStatusDisplayMap[claimStatusEnum.RejectedMultiple],
	[claimNumberStatus.RejectedByDuplicateClaim]: claimStatusDisplayMap[claimStatusEnum.RejectedByDuplicateClaim],
	[claimNumberStatus.RejectedByDuplicateInvoice]: claimStatusDisplayMap[claimStatusEnum.RejectedByDuplicateInvoice],
});

const claimStatusToNumberMap = Object.freeze({
	[claimStatusEnum.NewClaim]: claimNumberStatus.New,
	[claimStatusEnum.DraftClaim]: claimNumberStatus.Draft,
	[claimStatusEnum.RejectedWaitingPeriod]: claimNumberStatus.RejectedWaitingPeriod,
	[claimStatusEnum.RejectedSectionLimit]: claimNumberStatus.RejectedSectionLimit,
	[claimStatusEnum.RejectedByConditionLimit]: claimNumberStatus.RejectedByConditionLimit,
	[claimStatusEnum.RejectedByMultipleConditions]: claimNumberStatus.RejectedByMultipleConditions,
	[claimStatusEnum.ClosedPaid]: claimNumberStatus.ClosedPaid,
	[claimStatusEnum.Settled]: claimNumberStatus.Settled,
	[claimStatusEnum.RejectionPending]: claimNumberStatus.RejectionPending,
	[claimStatusEnum.FraudCheck]: claimNumberStatus.FraudCheck,
	[claimStatusEnum.MissingInfo]: claimNumberStatus.MissingInfo,
	[claimStatusEnum.RejectedManually]: claimNumberStatus.RejectedManually,
	[claimStatusEnum.FraudCheckOk]: claimNumberStatus.FraudCheckOk,
	[claimStatusEnum.FraudCheckFailed]: claimNumberStatus.FraudCheckFailed,
	[claimStatusEnum.ReassessmentPending]: claimNumberStatus.ReassessmentPending,
	[claimStatusEnum.RejectedByPreExistingCondition]: claimNumberStatus.RejectedByPreExistingCondition,
	[claimStatusEnum.RejectedByNoActiveCoverPeriod]: claimNumberStatus.RejectedByNoActiveCoverPeriod,
	[claimStatusEnum.PreAuthorisationCompleted]: claimNumberStatus.PreAuthorisationCompleted,
	[claimStatusEnum.RejectedByNoProductCoverage]: claimNumberStatus.RejectedByNoProductCoverage,
	[claimStatusEnum.RejectedMultiple]: claimNumberStatus.RejectedMultiple,
	[claimStatusEnum.RejectedByDuplicateClaim]: claimNumberStatus.RejectedByDuplicateClaim,
	[claimStatusEnum.RejectedByDuplicateInvoice]: claimNumberStatus.RejectedByDuplicateInvoice,
});

const reportClaimStatusDisplayMap = Object.freeze({
	[claimStatusEnum.NewClaim]: 'New',
	[claimStatusEnum.DraftClaim]: 'Draft',
	[claimStatusEnum.Rejected]: 'Rejected',
	[claimStatusEnum.ClosedPaid]: 'Closed-Paid',
	[claimStatusEnum.Settled]: 'Settled',
	[claimStatusEnum.FraudCheck]: 'Fraud Check',
	[claimStatusEnum.RejectionPending]: 'Rejection Pending',
	[claimStatusEnum.MissingInfo]: 'Missing Info',
	[claimStatusEnum.RejectedManually]: 'Rejected Manually',
	[claimStatusEnum.FraudCheckOk]: 'Fraud Check Ok',
	[claimStatusEnum.FraudCheckFailed]: 'Fraud Check Failed',
	[claimStatusEnum.ReassessmentPending]: 'Reassessment Pending',
	[claimStatusEnum.PreAuthorisationCompleted]: 'Pre-Authorisation Completed',
});

const claimStatusColorsMap = Object.freeze({
	[claimStatusDisplayMap.NewClaim]: 'text-success',
	[claimStatusDisplayMap.DraftClaim]: 'text-primary',
	[claimStatusDisplayMap.ClosedPaid]: 'text-white-50',
	[claimStatusEnum.Rejected]: 'text-white-50',
	[claimStatusDisplayMap.Settled]: 'text-purple',
	[claimStatusDisplayMap.RejectionPending]: 'text-purple',
	[claimStatusDisplayMap.FraudCheck]: 'text-yellow',
	[claimStatusDisplayMap.MissingInfo]: 'text-pink',
	[claimStatusDisplayMap.RejectedManually]: 'text-white-50',
	[claimStatusDisplayMap.FraudCheckOk]: 'text-primary',
	[claimStatusDisplayMap.FraudCheckFailed]: 'text-white-50',
	[claimStatusDisplayMap.ReassessmentPending]: 'text-yellow',
	[claimStatusDisplayMap.PreAuthorisationCompleted]: 'text-purple',
});

const claimNumberStatusToPotTypeMap = {
	[claimNumberStatus.Settled]: WorkQueueType.PaymentApproval,
	[claimNumberStatus.FraudCheck]: WorkQueueType.FraudCheck,
	[claimNumberStatus.RejectionPending]: WorkQueueType.RejectionApproval,
	[claimNumberStatus.MissingInfo]: WorkQueueType.Chasing,
	[claimNumberStatus.ReassessmentPending]: WorkQueueType.ReassessmentPending,
	[claimNumberStatus.Draft]: WorkQueueType.DraftClaims,
	[claimNumberStatus.FraudCheckOk]: WorkQueueType.DraftClaims,
};

const claimStatuses = Object.entries(claimStatusDisplayMap)
	.map(([key, value]) =>
		({
			value: key,
			description: value,
		}),
	);

const extendedContinuationParentingDisplayStatuses = [
	reportClaimStatusDisplayMap[claimStatusEnum.Settled],
	reportClaimStatusDisplayMap[claimStatusEnum.RejectionPending],
	reportClaimStatusDisplayMap[claimStatusEnum.PreAuthorisationCompleted],
];

const finalClaimDisplayStatuses = [
	reportClaimStatusDisplayMap[claimStatusEnum.ClosedPaid],
	reportClaimStatusDisplayMap[claimStatusEnum.Rejected],
	reportClaimStatusDisplayMap[claimStatusEnum.RejectedManually],
	reportClaimStatusDisplayMap[claimStatusEnum.FraudCheckFailed],
];

const finalClaimStatuses = [
	claimNumberStatus.RejectedWaitingPeriod,
	claimNumberStatus.RejectedSectionLimit,
	claimNumberStatus.RejectedByConditionLimit,
	claimNumberStatus.RejectedByMultipleConditions,
	claimNumberStatus.ClosedPaid,
	claimNumberStatus.RejectedManually,
	claimNumberStatus.FraudCheckFailed,
	claimNumberStatus.RejectedByPreExistingCondition,
	claimNumberStatus.RejectedByRelatedCondition,
	claimNumberStatus.RejectedByNoActiveCoverPeriod,
	claimNumberStatus.RejectedByNoProductCoverage,
	claimNumberStatus.RejectedMultiple,
	claimNumberStatus.RejectedByDuplicateClaim,
	claimNumberStatus.RejectedByDuplicateInvoice,
];

const rejectedclaimStatuses = [
	claimNumberStatus.RejectedWaitingPeriod,
	claimNumberStatus.RejectedSectionLimit,
	claimNumberStatus.RejectedByConditionLimit,
	claimNumberStatus.RejectedByMultipleConditions,
	claimNumberStatus.RejectedManually,
	claimNumberStatus.RejectedByPreExistingCondition,
	claimNumberStatus.RejectedByNoActiveCoverPeriod,
	claimNumberStatus.RejectedByRelatedCondition,
	claimNumberStatus.RejectedByNoProductCoverage,
	claimNumberStatus.RejectedMultiple,
	claimNumberStatus.RejectedByDuplicateClaim,
	claimNumberStatus.RejectedByDuplicateInvoice,
];

const saveClaimAsDraftAllowedStatuses = [
	claimNumberStatus.Draft,
	claimNumberStatus.New,
	claimNumberStatus.FraudCheckOk,
];

const outcomeState = Object.freeze({
	Paid: 0,
	Rejected: 1,
});

const readOnlyClaimStates = [
	...finalClaimStatuses,
	claimNumberStatus.Settled,
	claimNumberStatus.ReassessmentPending,
	claimNumberStatus.RejectionPending,
];

export {
	claimStatusEnum,
	claimNumberStatus,
	claimStatusToNumberMap,
	claimNumberStatusDisplayMap,
	claimStatusDisplayMap,
	claimStatuses,
	reportClaimStatusDisplayMap,
	extendedContinuationParentingDisplayStatuses,
	finalClaimDisplayStatuses,
	finalClaimStatuses,
	claimNumberStatusToPotTypeMap,
	claimStatusColorsMap,
	outcomeState,
	rejectedclaimStatuses,
	saveClaimAsDraftAllowedStatuses,
	readOnlyClaimStates,
};
