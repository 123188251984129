<template>
	<div>
		<div class="container px-20 py-10">
			<div class="row no-gutters font-weight-bold pb-5">
				Filter By
			</div>
			<div v-if="checkboxFilters.length">
				<div
					v-for="checkboxFilter of checkboxFilters"
					:key="checkboxFilter.key"
				>
					<aq-checkbox
						v-if="checkboxFilter.visible"
						:data-qa="`claimsFilter_checkbox_${checkboxFilter.key}`"
						class="mb-10"
						:label="checkboxFilter.label"
						v-model="checkboxFilter.value"
					/>
				</div>
			</div>
			<div v-if="selectFilters.length">
				<div
					class="row no-gutters pb-5"
					v-for="selectFilter of selectFilters"
					:key="selectFilter.key"
				>
					<div class="col-11">
						<aq-select
							:data-qa="`claimsFilter_input_${selectFilter.key}`"
							option-label="description"
							class="w-100"
							v-model="selectFilter.value"
							track-by="id"
							:no-result-message="selectFilter.noResultMessage"
							:options="selectFilter.options"
							:label="selectFilter.label"
							:multiple="selectFilter.multiselect"
						/>
					</div>
				</div>
			</div>
			<div class="row font-weight-bold no-gutters py-5">
				Sort
			</div>
			<div
				class="row no-gutters"
				v-if="sortingToApply"
			>
				<div class="col-11">
					<aq-select
						data-qa="claimFilter_input_sortBy"
						:options="sortingToApply.columns"
						label="Sort By"
						option-label="fieldView"
						class="w-100"
						v-model="sortingToApply.value"
						no-result-message="No Sorting Columns"
						track-by="field"
					/>
				</div>
				<div class="col-1 pl-6">
					<sort-direction
						class="pt-16 cursor-pointer sorting-selector"
						:direction="sortingToApply.direction"
						@click.native="onChangeSortingDirection"
					/>
				</div>
			</div>
		</div>
		<div class="row no-gutters py-10 pl-40 pr-10 claim-filters-footer d-flex align-items-center">
			<a
				class="text-underline cursor-pointer"
				@click="onClearFilters"
			>
				Clear
			</a>
			<button
				class="btn btn-primary ml-auto"
				type="button"
				@click="onApplyFilters"
			>
				Run
			</button>
		</div>
	</div>
</template>

<script>
import SortingDirection from '@commonServices/models/SortingDirection';
import FilterComponentTypeEnum from '@commonServices/models/FilterComponentTypeEnum';
import SortDirection from '@commonWidgets/SortDirection';

export default {
	name: 'ClaimsFilter',
	components: { SortDirection },
	props: {
		filters: {
			type: Array,
			required: false,
		},
		sorting: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			filtersToApply: [],
			sortingToApply: null,
		};
	},
	mounted () {
		this.filtersToApply = this.filters.map(f => ({ ...f }));
		this.sortingToApply = { ...this.sorting };
	},
	computed: {
		checkboxFilters () {
			return this.filtersToApply.filter(filter => filter.type === FilterComponentTypeEnum.Checkbox && filter.visible);
		},
		selectFilters () {
			return this.filtersToApply.filter(filter => filter.type === FilterComponentTypeEnum.Select && filter.visible);
		},
	},
	methods: {
		onApplyFilters () {
			this.$emit('apply-filters', { filtersToApply: this.filtersToApply, sortingToApply: this.sortingToApply });
		},
		onClearFilters () {
			this.$emit('clear-filters');
		},
		onChangeSortingDirection () {
			this.sortingToApply.direction = this.sortingToApply.direction === SortingDirection.Ascending
				? SortingDirection.Descending
				: SortingDirection.Ascending;
		},
	},
};
</script>

<style lang="scss" scoped>
  .claim-filters-footer {
    background: var(--statusBarBg);
  }

  .text-underline {
    text-decoration: underline;
    color: $body-color;

    &:hover {
      color: $primary;
    }
  }
</style>
