import httpClient from '@commonServices/httpClient';
import { getFileExtension, MimeTypes } from '@commonServices/utils/general';

function _getUploadFileConfig (uploadProgressCallback) {
	return {
		onUploadProgress: uploadProgressCallback,
		headers: {
			'Content-Type': 'multipart/form-data; charset=utf-8',
		},
	};
}

function applyParamIfNotNull (url, paramKey, paramValue) {
	if (paramValue) {
		url = url + `&${paramKey}=${paramValue}`;
	}
	return url;
}

export default {
	// #region requests

	uploadClaimFile (file, claimId, document, copyInvoice, uploadProgressCallback) {
		let url = `/api/claims/files/claim/${claimId}?category=${document.category}&copyInvoice=${copyInvoice}`;

		document.missingItemIds.forEach(id => {
			url = url + `&missingItemIds=${id}`;
		});
		url = applyParamIfNotNull(url, 'vetIdentifier', document.vetId);
		url = applyParamIfNotNull(url, 'description', document.description);

		const config = _getUploadFileConfig(uploadProgressCallback);
		return httpClient(url, { omitSpinner: true, config })
			.post(file);
	},
	uploadPetFile (file, petId, document, uploadProgressCallback) {
		let url = `/api/claims/files/pet/${petId}?category=${document.category}`;
		url = applyParamIfNotNull(url, 'vetIdentifier', document.vetId);
		url = applyParamIfNotNull(url, 'description', document.description);
		const config = _getUploadFileConfig(uploadProgressCallback);
		return httpClient(url, { omitSpinner: true, config })
			.post(file);
	},
	uploadCustomerFile (file, customerId, document, uploadProgressCallback) {
		let url = `/api/claims/files/customer/${customerId}?category=${document.category}`;
		url = applyParamIfNotNull(url, 'vetIdentifier', document.vetId);
		url = applyParamIfNotNull(url, 'description', document.description);
		const config = _getUploadFileConfig(uploadProgressCallback);
		return httpClient(url, { omitSpinner: true, config })
			.post(file);
	},
	getFileSrc (fileId) {
		return `/api/claims/files/${fileId}`;
	},
	getClaimFiles (claimId) {
		return httpClient(`/api/claims/files/claim/${claimId}`)
			.get();
	},
	getPetFiles (petId) {
		return httpClient(`/api/claims/files/pet/${petId}`)
			.get();
	},
	getCustomerFiles (customerId) {
		return httpClient(`/api/claims/files/customer/${customerId}`)
			.get();
	},
	updateFileMetadata (fileId, data, isClaimFile) {
		let url = `/api/claims/files/${fileId}/metadata`;
		if (!isClaimFile) {
			url += '/common';
		}
		const request = {
			...data,
			vetIdentifier: data.vetId,
		};
		return httpClient(url)
			.put(request);
	},
	deleteFile (fileId) {
		return httpClient(`/api/claims/files/${fileId}`)
			.delete();
	},
	getBrandLogo (brandId) {
		return `${ClaimsConfig.CLAIMS_ROOT_API}/api/claims/files/brand/${brandId}/logo`;
	},
	getEmailData (fileId) {
		return httpClient(`/api/claims/emails/${fileId}`)
			.get();
	},
	getProductFiles (productId) {
		return httpClient(`/api/claims/files/product/${productId}/files`)
			.get();
	},
	getDuplicateInvoices (fileId) {
		return httpClient(`/api/invoices/${fileId}/duplicates`, { omitSpinner: true })
			.get();
	},
	// #endregion

	// #region logic

	getFileBlob (src, mimeType) {
		return httpClient(src)
			.getBlob()
			.then((data) => (new Blob([data], { type: mimeType })));
	},
	async getTempSrc (fileId, mimeType) {
		const documentSrc = this.getFileSrc(fileId);
		const fileBlob = await this.getFileBlob(documentSrc, mimeType);
		return window.URL.createObjectURL(fileBlob);
	},
	async downloadFile (id, fileName, mimeType) {
		const link = window.document.createElement('a');
		link.href = await this.getTempSrc(id, mimeType);
		link.download = fileName;
		link.click();
	},
	async getEmailAttachment (fileId, attachmentName, attachmentId, mimeType) {
		const file = await this.getFileBlob(`/api/claims/emails/${fileId}/attachments/${attachmentId}`, mimeType);
		const link = window.document.createElement('a');
		link.href = window.URL.createObjectURL(file);
		link.download = attachmentName;
		link.click();
	},
	async getPolicyWordingUrl (productId, file) {
		if (file) {
			const hash = file.hash;
			const mimeType = MimeTypes[getFileExtension(file.fileName)];
			const wordingFileSrc = `/api/claims/files/product/${productId}/files/${hash}`;
			try {
				const fileBlob = await this.getFileBlob(wordingFileSrc, mimeType);
				return window.URL.createObjectURL(fileBlob);
			} catch {
				return null;
			};
		}

		return null;
	},
	generateFileName (fileName, exportType) {
		const extension = exportType.description.toLowerCase();
		return `${fileName}.${extension}`;
	},

	// #endregion
};
