export function getPopupElementSize (element) {
	const originalDisplay = element.style.display;
	const originalVisibility = element.style.visibility;
	element.style.display = 'block';
	element.style.visibility = 'hidden';
	const styles = window.getComputedStyle(element);
	const width
      = element.offsetWidth + parseInt(styles.marginLeft, 10) + parseInt(styles.marginRight, 10);
	const height
      = element.offsetHeight + parseInt(styles.marginTop, 10) + parseInt(styles.marginBottom, 10);
	element.style.display = originalDisplay;
	element.style.visibility = originalVisibility;
	return { width, height };
}

export function getRelativeVerticalPosition (el, targetHeight) {
	let top = 0;
	const relativeRect = el.getBoundingClientRect();
	const dh = document.documentElement.clientHeight;

	let placementY = 'bottom';
	if (relativeRect.top + relativeRect.height + targetHeight <= dh) {
		top = relativeRect.height;
	} else {
		top = -targetHeight;
		placementY = 'top';
	}

	return { top: `${top}px`, placementY };
}

export function getVerticalPositionByPlacement (el, targetHeight, placementY) {
	let top = 0;
	const relativeRect = el.getBoundingClientRect();

	if (placementY === 'bottom') {
		top = relativeRect.height;
	} else {
		top = -targetHeight;
	}
	return { top: `${top}px` };
}
